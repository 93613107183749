.skeleton {
    display: flex;
    border-radius: 5px;
    animation: pulse 1.5s infinite;
    align-items: center;
    gap: 2rem;
    .skeletonThumbnail {
        width: 5rem;
        height: 3.5rem;
        border-radius: 0.3rem;
        border-radius: 5px;
        background-color: #8884846e;
        margin-inline-start: 0.5rem;
    }
    .skeletonTitleContainer{
        display: flex;
        flex-direction: column;
        flex: 0.8;
        gap: 2rem;
        .skeletonTitle {
            width: 90%;
            height: 0.2rem;
            border-radius: 0.3rem;
            border-radius: 5px;
            background-color: #8884846e;
        }
    }

}

@keyframes pulse {
    0% {
        background-color: #e0e0e0;
    }
    50% {
        background-color: #f0f0f0;
    }
    100% {
        background-color: #e0e0e0;
    }
}
