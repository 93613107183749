.gravatar
{
    border-radius: 100px;
    background-color: white;
}

.disabled
{
    filter: grayscale(95%);
}
