.wrapper{
    max-width: 500px;
justify-content: center;
    .weekdays{
        display: flex;
        margin-block-start: 0.5rem;
        margin-block-end: 0.5rem;
        justify-content: center;
    }
    .hours{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-block-start: 0.5rem;
        margin-block-end: 0.5rem;
    }
    .realtime{
        margin-block-start: 1rem;
    }
}