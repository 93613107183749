.wrapper
{
    position: relative;
}

.dropdown
{
    position: absolute;
    inset-block-end: 0;
    transform: translate(0, 100%);
    z-index: 10;

    width: calc(100% - 2px);
    max-height: 20rem;

    overflow: auto;

    background-color: white;
    border: 1px solid lightgray;
    border-block-start: none;

    display: flex;
    flex-direction: column;

    padding: .3rem 0;
}

.dropdownHeader
{
    font-weight: bold;
    font-size: .8rem;
    opacity: .8;

    margin-inline-start: .4rem;
    margin-inline-end: .4rem;
    margin-block-end: .3rem;
}
.dropdownHeader:not(:first-child)
{
    margin-block-start: .4rem;
    padding-block-start: .4rem;
}

.dropdownItem
{
    padding: .4rem .4rem;
}

.active
{
    cursor: pointer;
    background-color: #EEEEEE;
}