.quiz{
    padding: 1rem;

    .buttonsWrapper{
        display: flex;
        justify-content: center;
        
        .btn{
            flex-grow: 1;
        }
        
    }
}