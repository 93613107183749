.filtersTopContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-inline-start: 1.5rem;
    margin-inline-end: 1.5rem;
    height: 2.5rem;
    border-radius: 0.2rem;
    flex-wrap: wrap;
    margin-block-end: 1rem;
    
    @media screen and (max-width: 768px) {
      gap: 0.5rem;
    }
    .filterButtonsContainer{
      display: flex;
      align-items: center;
      gap: 1rem;
      height: 2rem;
      
      @media screen and (max-width: 768px) {
        justify-content: center;
        align-items: center;
        width: 100%;
        
      }
    }

    .queryIssue{
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color: #da2923;
      font-size: 0.8rem;
      font-weight: 500;
      position: absolute;
      top: -18px;
      left: 50px;
      //z-index: 9999;
      @media screen and (max-width: 768px) {
        justify-content: center;
        align-items: center;
        width: 100%;
      }
    }

    .noDateMessage{
      background-color: #ffffffbd;
    }

    .clipFilterClearAll {
        color: #23527c;
        cursor: pointer;
        width: 5rem;
        display: flex;
        align-items: center;
        gap: 0.2rem;
      }

      .hideQueryVisButton{
        cursor: pointer;
        position: absolute;
        display: flex;
        align-items: center;
        right: 0rem;
        top: 0;
        margin: 0rem;
        z-index: 9999;
        &:hover{
          cursor: pointer;
          background-color: #23527c !important;
          transition: 0.3s ease-in-out;
        }
        .hideEyeIcon{
          color: #ffffff;
          &:hover{
            color: rgb(196, 160, 27);
          }
        }
      }

    .clipFilterButton {
        padding: 5px 10px;
        height: 2.4rem;
        border: none;
        border-radius: 5px;
        background-color: #23527c;
        color: white;
        width: fit-content;
        cursor: pointer;
        @media screen and (max-width: 768px) {
          width: 100%;
        }
      &:hover {
        background-color: #1a3f5f;
      }
      }

      .queryVisWrapper{
          position: absolute;
          display: flex;
          justify-content: center;
          width: fit-content;
          z-index: 999;
          top: 3.5rem;
      }

    .clipSearchInput{
      position: relative;
      flex: 1;
      display: flex;
      justify-content: center;
      width: 100%;
      border-radius: 0.2rem;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
      margin-top: 0.1rem;
      
      &:hover{
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      }
      >div{
        border: none;
        margin-inline-end: 4rem;
      }
      input {
        background-color: #FFF0;
        border: none;
        width: 100%;
      }
      svg{
        color: #23527c;
        position: sticky;
      }
      @media screen and (max-width: 768px) {
        width: 100%;
        display: flex;
        justify-content: center;
        order: -1;
      }
    }
  }

  .searchBarContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: 9980;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  .backToResultsContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}