  .genericTableFilterContainer{
  display: flex;
  flex-direction: column;
  background-color: #e8ebee;
  height: 20rem;
  gap: 0.4rem;
  position: absolute;
  z-index: 99;
  width: 12rem;
  font-weight: 400;
  font-size: 0.9rem;
  padding-inline-start: 0.2rem;
  padding-inline-end: 0.2rem;
  padding-block-start: 0.2rem;
  padding-block-end: 0.2rem;

  .allCheckboxContainer{
     
    .genericTableFilterRow {
      //add dash instead empty checkbox to "All"
        // input[type="checkbox"] {
        //   &:not(:checked) {
        //     &:after {
        //       content: '';
        //       position: absolute;
        //       top: 50%;
        //       left: 50%;
        //       transform: translate(-50%, -50%);
        //       width: 10px;
        //       height: 2px;
        //       background-color: #000000;
        //     }
        //   }
        // }
      }
    }
  
  
    .genericTableFilterRowsContainer{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      background-color: #ffffff;
      padding-block: 0.1rem;
      border-radius: 0.4rem;
      max-height: 10rem;
      overflow-x: hidden;
      overflow-y: scroll;
      color: #000000;
      

    }
    .genericTableFilterRow{
      display: flex;
      margin-inline-start: 1rem;
      align-items: center;
      width: 100%;
      input[type="checkbox"] {
        cursor:pointer;
        appearance: none;
        background-color: #ffffff;
        width:1rem;
        height:  1rem;
        position: relative;
        border: 2px solid gray;
        &:checked {
          background-color: #ffffff;
          &:after {
            content: '';
            position: absolute;
            top: -2px;
            left: 3px;
            width: 3px;
            height: 10px;
            border: solid #000000;
            border-width: 0 3px 3px 0;
            transform: rotate(45deg);
          }
        }
      }
  }
    .genericTableFilterSearchInput {
      color: #000000;
      input{
        padding-inline-start: 2.4rem !important;
        border: none;
        font-size: 0.9rem;
        @media(max-width: 1341px){
          min-height: 1.5rem !important;
      }
      }
  }
  .genericTableFilterButtonsContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
      .button{
        height: 2rem;
        width: 4.5rem;
        font-size: 0.9rem;
        @media(max-width: 1341px){
            height: 1.5rem;
            width: calc(65% - 2rem);
        }
    }
  }

  }

  