.wrapper{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .cards{
        display: flex;
        height: auto;
        justify-content: center;
        align-items: center;
    }

    .trial{
        margin-block-start: 3rem;
    }
}