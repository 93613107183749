.infoBox {
  background-color: rgb(255, 255, 255);
  position: absolute;
  z-index: 99;
  border-radius: 0.5rem;
  border: 1px solid lightgray;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: fit-content;
  padding-inline-start: 0.5rem;
  padding-inline-end: 0.5rem;
  padding-block-start: 0.5rem;
  padding-block-end: 0.5rem;
  min-width: 6rem;
  width: fit-content;
  .infoItem{
    color: #81a9c4;
    font-weight: 600;
  }
  }

  .infoIcon{
    color: #000000;
    font-size: 1rem;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    margin-right: 2rem;
    &:hover {
        color: #707070;
        transition: 0.2s ease-in-out;
    }
}