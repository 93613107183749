.logoContainer{
    display: flex;
    //align-items: center;
    flex-direction: column;
    //overflow-y: auto;
    flex: 0.1;
    min-width: 10rem;
    gap: 0.3rem;
    height: 100%;
    background-color: #f7f7f7;
    border-radius: 0.5rem;
    padding: 0rem 1rem 0 1rem;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    @media (max-width: 864px){
        overflow-x: auto;
        gap: 0.5rem;
        padding: 0.5rem;
        order: 1;
    }
    .elementsDivider{
        border: 0.5px solid #d8d8d8;
        width: 100%;
        margin-block-start: 0.3rem;
        margin-block-end: 0.1rem;
    }

    .activeIconRow{
        display: flex;
        align-items: center;
        gap: 0.2rem;
        background-color: #dbdbdb;
        border-left: 2px solid rgb(35, 82, 124);
        padding-inline-start: 1px;
        &:hover{
            cursor: pointer;
            background-color:#dbdbdb;
            transition: ease-in-out 0.3s;
        }
    }
    
    .iconRow{
        display: flex;
        align-items: center;
        gap: 0.1rem;
      
        width: 100%;
        //every one except last one has border-bottom
        border-bottom: #d0d0d0 1px solid;
        &:last-child{
            border-bottom: none;
        }
        &:hover{
            cursor: pointer;
            background-color:#dbdbdb;
            transition: ease-in-out 0.3s;
        }
    }
    
    .iconText{
        padding: 0.1rem;
        margin: 0;
        font-size: 0.9rem;
        padding: 0;
        text-overflow: ellipsis;
        line-height: 0.8rem;
    }

    .typeIconContainer{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        width: 100%;
        font-size: 1rem;
        margin-block-start: 0.5rem;
        .typeIcon{
            font-size: 1rem;
            color: rgb(0, 56, 85);
            box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
            &:hover{
                cursor: pointer;
                transition: ease-in-out 0.3s;
                color: rgb(22, 121, 170);
            }
        }
        .activeTypeIcon{
            font-size: 1rem;
            color: rgb(215, 139, 33);
            &:hover{
                cursor: pointer;
                color: rgb(221, 162, 79);
            }
        }
    }
    .sentimentFiltersContainer{
        display: flex;
        gap: 0.3rem;
       
    }
    .searchInputChannel{
        color: #000000;
        width: 100%;

        input{
          font-size: 0.9rem;
          padding: 0;
          width: 100%;
          padding-inline-start: 0.2rem;
          border: none;
          box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
          height: 0.2rem;
          min-height: 20px;
          @media(max-width: 1341px){
            min-height: 1.5rem !important;
        }
        }
    }

    .sentiment {
        cursor: pointer;
        color: #23527c;
    }

    .activeSentiment {
        color: rgb(215, 139, 33);
    }

    .selectedChannelsCounter{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.2rem;
        font-size: 0.8rem;
        color: #000000;
        margin-block-start: 0.5rem;
        margin-block-end: 0.5rem;
    }
   
}