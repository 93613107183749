.wrapper {
    display: flex;
    flex-direction: column;
    .contentWrapper {
        display: flex;
        margin-block-end: 2rem;
        .overview {
            margin-inline-start: 1rem;
            margin-inline-end: 1rem;
            .total {
                background-color: #d7dde9;
                display: flex;
                justify-content: space-between;
                // background-color: red;
            }
        }
        .details {
            margin-inline-start: 1rem;
            margin-inline-end: 1rem;
            .cp {
                display: flex;
                margin-block-start: 0.8rem;
                flex-direction: row;
            }
        }
    }
}
