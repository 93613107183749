.clipCardContainer{
    display: flex;
    gap: 0.4rem;
    align-items: center;
    font-size: 1rem;
    border-radius: 0.8rem;
    margin-top: 0.1rem;
    position: relative;
    box-sizing: border-box;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    &:hover{
        cursor: pointer;
        background-color:#f5f5f5;
    }
    .summaryHeadlineContainer{
        .editedSummary{
            font-size: 0.9rem;
            padding-inline-end: 9.2rem;
            color: rgba(35, 82, 124, 0.836);
            font-weight: 500;
            overflow: hidden;
            text-overflow: ellipsis;
           
        }
        .editedHeadline {
            font-size: 0.9rem;

            color: #d18e2a;
            font-weight: 500;
            text-overflow: ellipsis;
        }
    }

    .clipTitleContainer{
        width: 100%;
        font-weight: 500;
        color: #000000;
        display: flex;
        //align-items: center;
        justify-content: center;
        flex-direction: column;
        //flex-wrap: wrap;
        margin-inline-start: 0.5rem;
        margin-inline-end: 1rem;
        margin-block-start: 1.5rem;
        .channelName{
            display: flex;
            align-items: center;
            gap: 0.2rem;
            font-size: 0.9rem;
            font-weight: 600;
            color: rgb(35, 82, 124);
            &:after{
                content: "•";
                margin-inline-start: 0.2rem;
                margin-inline-end: 0.2rem;
            }
        }
        .programTitle{
            font-size: 0.9rem;
            font-weight: 500;
            color: #c4a01b;
            display: flex;
        }
        .timeCodeChannel{
            display: flex;
            gap: 0.2rem;
            align-items: center;
        }
        .clipTitle{
            display: flex;
            gap: 0.2rem;
            align-items: center;
            justify-content: space-between;
            font-size: 0.9rem;
            font-weight: 500;
            box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
            border-radius: 0.2rem;
            color: #000000;
            padding: 0.2rem;
            width: calc(100% - 3rem);
            position: absolute;
            top: 0.5rem;
            left: 1.5rem;
        }
        .clipDesc{
            font-size: 0.9rem;
            color: #000000;
            font-weight: 400;
            margin-inline-end: 1.9rem;
        }

        .cardHr{
            border: 0;
            height: 0;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            border-bottom: 1px solid rgba(255, 255, 255, 0.3);
            width: 100%;
            margin-block-start: 0.4rem;
            margin-block-end: 0.2rem;
        }

        .ave {
            font-size: 0.9rem;
            color: rgb(35, 82, 124);
            font-weight: 400;
            &:before {
                content: "•";
                margin-inline-start: 0.2rem;
                margin-inline-end: 0.2rem;
            }
        }
        
        .aveRTL {
            @extend .ave;
            direction: ltr;
            &:after {
                content: "•";
                margin-inline-start: 0.2rem;
                margin-inline-end: 0.2rem;
            }
            &:before {
                content: none;
            }
        }

        .copiedMessage {
            position: absolute;
            background: rgba(0, 0, 0, 0.7);
            color: white;
            padding: 5px 10px;
            border-radius: 5px;
            top: 10px;
            right: 10px;
            z-index: 1000;
        }

        .clipDescArabic{
            @extend .clipDesc;
            direction: rtl;
            text-align: right;
            //line-height: 1rem;
        }
    }

    .backFillCheckboxRTL{
        @extend .backFillCheckbox;
        margin-inline-start: 1rem;
    }

    .sentimentRTL{
        cursor: pointer;
        position: absolute;
        top: 3.5rem;
        left: 1.5rem;
        padding: 0.1rem;
        display: flex;
        background: none;
        align-items: center;
        justify-content: center;
        text-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
        .sentimentIcon{
            font-size: 0.7rem;
            color: #000000;
            &:hover{
                color: #23527c;
            }
        }
    }

    .profileTitle{
        width: max-content;
        right: 1.4rem;
        font-size: 0.9rem;
        font-weight: 500;
        border-radius: 0.3rem;
        padding: 0.15rem;
        background-color: #23527c;
        color: #ffffff;
        position: absolute;
        bottom: 1.2rem;


    }


    // .profileTitleContainer{
    //     position: absolute;
        
    //     width: max-content;
    //     right: 1.4rem;
    //     font-size: 1.2rem;
    //     color: #000000;
    //     &:hover{
    //         cursor: pointer;
    //         color: #4686be;
    //     }
    //     color: rgb(35, 82, 124);

    // }
    // .profileTitleContainerRTL {
    //     @extend .profileTitleContainer;
    //     right: unset;
    //     left: calc(1.4rem);
    //     text-align: right;
        
    // }

    .profileTitleRTL{
        @extend .profileTitle;
        direction: rtl;
        text-align: right;
        left: 1.4rem;
        right: unset;
    }

    .clipKeyframe{
        margin-block-start: 2rem;
        min-width: 7rem;
        border-radius: 0.3rem;
        margin-inline-start: 1.5rem;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        transition: transform 0.3s ease-in-out;
        &:hover{
            cursor: pointer;
            transform: scale(1.1);
        }
    }
}

.clipCardContainerRTL{
    @extend .clipCardContainer;
    direction: rtl;
}

.clipTitleContainerRTL{
    @extend .clipTitleContainer;

}

.sentiment{
    cursor: pointer;
    position: absolute;
    top: 3.5rem;
    right: 1.2rem;
    padding: 0.1rem;
    display: flex;
    background: none;
    align-items: center;
    justify-content: center;
    text-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.sentimentIcon{
    font-size: 0.7rem;
    color: #000000;
    &:hover{
        color: #23527c;
    }
}

.playIcon {
    position: absolute;
    top: 50%;
    left: 3.5rem;
    transform: translate(50%, -10%);
    font-size: 2rem;
    color: #fafafacd;

    &:hover {
        color: #cecece;
        transition: 200ms ease-in-out;
        cursor: pointer;
    }
}

.playIconBackFill{
    position: absolute;
    top: 50%;
    left: 5rem;
    transform: translate(50%, -10%);
    font-size: 2rem;
    color: #fafafacd;
    &:hover {
        color: #cecece;
        transition: 200ms ease-in-out;
        cursor: pointer;
    }
}

.playIconRTL {
    @extend .playIcon;
    right: 3rem;
    left: unset;
    transform: translate(-50%, -10%);
}

.playIconBackFillRTL {
    @extend .playIconBackFill;
    right: 4.5rem;
    left: unset;
    transform: translate(-50%, -10%);
}

.playIconContainer{
    position: relative;
}

.duplicateIcon {
    position: absolute;
    writing-mode: vertical-rl;
    text-orientation: upright;
    top: 0.75rem;
    left: 0.3rem;
    font-weight: bold;
    font-size: 1rem;
    border-radius: 5px;
    color: rgb(35, 82, 124);
    // &:hover{
    //     cursor: pointer;
    //     color: #23527c;
    // }
}

.duplicateIconRTL {
    right: 0.2rem;
    left: unset;
}

.backFillCheckbox{
    padding-inline-start: 1rem;
    font-size: 1.2rem;
    color: #000000;
    &:hover{
        cursor: pointer;
        color: #23527c;
    }
}

.seenPrefix{
    font-size: 0.9rem;
    color: #000000;
    margin-inline-start: 0.2rem;
}

.duplicatesContainer{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 0.4rem;
    margin-block-start: 0.3rem;
    .clipsOutputContainer{
        display: flex;
        gap: 0.4rem;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        margin-block-start: 0.3rem;
    }
    .asSeenAt{
        font-size: 1rem;
        color: #000000;
        flex-wrap: wrap;
        margin-inline-start: 0.2rem;
        display: flex;
        align-items: center;
        gap: 0.4rem;
        
        .asSeenTime{
            font-size: 0.9rem;
            color: #000000;
            &:hover{
                cursor: pointer;
                color: #23527c;
            }
        }
    }

    .channelNameAs{
        font-size: 0.9rem;
        margin-inline-start: 0.2rem;
        margin-inline-end: 0.2rem;
        color: #23527c;
    }
    .xSecsLater{
        font-size: 0.9rem;
        color: #000000;
        margin-inline-start: 0.2rem;
        margin-inline-end: 2rem;
        &:hover{
            cursor: pointer;
            color: #23527c;
        }
    }

    .timeDifference{
        font-size: 0.9rem;
        color: #23527c;
    }
}

   

.basicNotificationsContainer {
    display: flex;
    //gap: 0.4rem;
    height: fit-content;
    width: fit-content;
    flex-direction: column;
    margin-block-start: 0.5rem;
    gap: 0.2rem;
    &:hover {
        cursor:initial;
        background-color: #f5f5f5;
    }
    .superTimesContainer{
        padding: 0;
        margin: 0;
        
        .superTimeStamp{
            //color:#23527c;
            // &:hover{
            //     cursor: pointer;
            //     color: #c4a01b;
            // }
            font-size: 0.9rem;
            //color: #000000;
            margin-inline-start: 0.2rem;
            margin-inline-end: 0.2rem;
        }
        .queryTitle{
            // font-size: 1rem;
            font-weight: 600;
            // margin-inline-start: 0.5rem;
            // margin-inline-end: 0.2rem;
            font-size: 0.9rem;
            //color: #000000;
            margin-inline-start: 0.2rem;
            &:hover{
                cursor: pointer;
                background-color: #23527c52;
            }
            //after first element add a gap

            &:nth-child(1){
                //color: #c4a01b;;
            }
        }
    }
}

.basicNotificationThumbnail {
    width: 60px;
    object-fit: cover;
    border-radius: 5px;
    &:hover {
        cursor: pointer;
        transform: scale(1.1);
    }
}

.basicNotificationKeyframe {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.basicNotificationTooltip {
    position: absolute;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
}

.basicNotificationCard{
    position: relative;
    //do not allow onclick events
    pointer-events: none;
    display: flex;
    gap: 0.4rem;
}

.basicNotificationCard:hover .basicNotificationTooltip {
    visibility: visible;
    opacity: 1;
    display: block;
}

.duplicateNotification {
    border: 1px dashed #ff6961;
    position: relative;
}

.duplicateIndicator {
    position: absolute;
    top: 2px;
    left: 2px;
    background-color: #ff6961;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 12px;
}

// .basicDescription{
//     font-size: 0.8rem;
//     color: #000000;
//     font-weight: 300;
//     margin-inline-end: 1.9rem;
//     &:hover{
//         cursor: pointer;
//         background-color: #23527c52;
//     }
// }



