.addButtonContainer{
    position: sticky;
    display: flex;
    justify-content: flex-end;
    bottom: 0;
    left: 80%;
    z-index: 99;
    width: fit-content;
    .addInput{
        position: absolute;
        right: 2.1rem;
        border: 1px rgb(211, 187, 141) solid;
        top: 50%;
        bottom: 0;
        transform: translateY(-50%);
        width: 7rem;
        height: 2rem;
        border-right: none;
        border-radius: 4px 0 0 4px;
        z-index: -1;
        background-color: #ffffff;
        font-size: 0.9rem;
        font-weight: 500;
        padding-inline-end: 1rem;
    }
    .errorText{
        position: absolute;
        right: 2.5rem;
        top: 10%;
        bottom: 0;
        transform: translateY(-50%);
        width: 7rem;
        height: 2rem;
        border-right: none;
        border-radius: 4px 0 0 4px;
        color: red;
    }
    &:dir(rtl) {
        right: 80%;
        justify-content: flex-start;
    
        .addInput {
          right: auto;
          left: 2.1rem;
          border-right: 1px rgb(211, 187, 141) solid;
          border-left: none;
          border-radius: 0 4px 4px 0;
        }
    
        .errorText {
          right: auto;
          left: 2.5rem;
        }
      }
}