.select {
    /* margin-block-start: .5rem; */
    border: 1px solid grey;
    border-radius: 4px;
    width: 100%;
    z-index: 9000;
}
.select > div:first-child
{
    border: none !important;
}

/* simple 2-button group, that spreads buttons out */
.buttonRow {
    display: flex;
    justify-content: space-between;
    margin-block-start: 1rem;
}

.buttonRow button
{
    margin: 0;
}