.backgroundPrimary {
    background-color: $primary !important;
  }

  .backgroundPrimary_disabled {
    background-color: $primary_disabled !important;
  }

  .backgroundPrimary_reverse {

    background-color: $primary_reverse !important;
    
  }
  
  .backgroundSecondary {
    background-color: $secondary !important;
  }
  
  .backgroundSuccess {
    background-color: $success !important;
  }
  
  .backgroundDanger
  {
    background-color: $danger !important;
  }
  .backgroundDangerLight{
    background-color: lighten($danger, 30%) !important;
  }
  
  .textPrimary {
    color: $primary !important;
  }
  
  .textSecondary {
    color: $secondary !important;
  }

  .textSecondaryHover:hover {
    color: $secondary !important;
  }
  
  .textSuccess {
    color: $success !important;
  }
  
  .textDanger
  {
    color: $danger !important;
  }
  
  .borderPrimary {
    border-color: $primary !important;
  }
  
  .borderSecondary {
    border-color: $secondary !important;
  }
  
  .borderSuccess {
    border-color: $success !important;
  }
  
  .borderDanger
  {
    border-color: $danger !important;
  }
  
  
  
  /* TODO: move below things somewhere else */
  
  .hit, .hitBox [class=hit]
  {
      color: $secondary;
  }

  
  :export{
    primary: $primary;
    secondary: $secondary;
    success: $success;
    danger: $danger;
    ci: $ci;
    ciFont: $ciFont;
  }