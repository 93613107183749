.wrapper
{
    display: flex;
    justify-content: center;

    width: 100%;
    height: calc(100% - .3rem);
}

.content
{
    width: calc(100% - 2rem);
    height: calc(100% - .3rem);
}

.usersList
{
    max-height: calc(100% - 9rem);
}