.bodyRow {
    display: flex;
    margin: auto;
    width: 100%;
    background-color: #ffffff;
    @media (max-width: 1024px) {
        display: flex;
        flex-direction: column;
        min-height: 3rem;
        height: fit-content;
        margin: 0;
    }
    &:hover {
        background-color: rgb(190, 190, 190);
        transition: 100ms ease-in-out;
        cursor: pointer;
    }
      
    &.rtl {
        flex-direction: row-reverse;

        .firstRowElements,
        .firstRowContainer,
        .cell {
            flex-direction: row-reverse;
        }

        .hoverInfoBox {
            align-items: flex-end;
        }

        .infoIconContainer {
            margin-inline-end: 0;
            margin-inline-start: 1rem;
        }
    }

    .mobileRowContainer {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        flex-wrap: wrap;
        font-size: 0.9rem;
        background-color: #fdfafa;
        .mobileColumn {
            display: flex;
            gap: 10px;
            >span{
                width: fit-content;
            }
          }
          &:hover {
            background-color: rgb(190, 190, 190);
            transition: 100ms ease-in-out;
            cursor: pointer;
        }
      }
      
    .firstRowContainer {
        display: flex;
        align-items: center;
        min-width: 15rem;
        height: 3.2rem;
        flex: 1.5;
        justify-content: space-between;
        @media (max-width: 1024px) {
          height: 5rem;
      }
        .firstRowElements {
            display: flex;
            @media (max-width: 1024px) {
                gap: 0.2rem;
                padding-inline-start: 2.5rem;
                align-items: center;
            }
            .arrowColumnOnMobile{
                @media (max-width: 1024px) {
                    position: absolute;
                    height: 100%;
                    width: 3rem;
                    top: 50%;
                    left: 0;
                    transform: translate(10%, -50%);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }
                
            }
        }
    }

    .cell {
        display: flex;
        align-items: center;
        min-width: 6rem;
        flex: 1;
        margin: auto;
        @media (max-width: 1024px) {
          margin-inline-start: 0.6rem;
          margin-block-start: 0.1rem;
      }
    }

    &.oddRow {
        background-color: rgb(238, 238, 238);
        background-clip: padding-box;
        &:hover {
            background-color: rgb(190, 190, 190);
            transition: 100ms ease-in-out;
            cursor: pointer;
        }
        @media (max-width: 1024px) {
        display: flex;
        flex-direction: column;
        min-height: 3rem;
        height: fit-content;
    }
    }

    .accordion{
        display: none;
    }

    .hoverInfoBox {
        background-color: rgb(255, 255, 255);
        position: absolute;
        left: 100%;
        right: 100%;
        z-index: 99;
        border-radius: 0.5rem;
        border: 1px solid lightgray;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        height: fit-content;
        padding-inline-start: 0.5rem;
        padding-inline-end: 0.5rem;
        padding-block-start: 0.5rem;
        padding-block-end: 0.5rem;
        min-width: 6rem;
        width: fit-content;

        .hoveredGroups {
            color: #81a9c4;
            font-weight: 600;
        }
    }

    .infoIconContainer {
        display: flex;
        margin-inline-end: 1rem;
        position: relative;
        .infoCircle {
            &:hover {
                color: #7d7d7d;
                transition: ease-in-out 200ms;
            }
        }
    }
}
