.wrapper
{
    overflow: auto;
}

.loadingMoreWrapper{
    padding-block-end: 1.5rem;
    padding-block-start: 1.5rem;
    width: 100%;
    background-color: white;
    opacity: 0.91;
}