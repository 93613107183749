.groupCard {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 90%;
    background-color: #eceff188;
    border: 1px solid lightgray;
    border-radius: 10px;
    margin-inline: 0.25rem;
    margin-block: 0.25rem;
    padding-inline: 0.5rem;
    padding-block: 0.5rem;
    font-weight: bold;
    gap: 1rem;
    height: 1.9rem;
    &:not(.selectedGroup):hover {
        background-color: #eceff1;
        cursor: pointer;
    }

    .penIcon {
        cursor: pointer;
        &:hover {
            color: #868585;
        }
    }
}
.selectedGroup {
    background-color: #81A9C4;
    color: #ffffff;
}
