.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    width: 100%;
    height: 100%;
    inset-block-start:0;
    inset-inline-start:0;
    z-index: 1;

    .content {
        width: 50%;
        height: 50%;
        max-width: 200px;
        max-height: 200px;
        display: flex;
        justify-content:space-around;
        align-items: center;
        background: white;
        flex-direction: column;
        padding: 2rem;
    }
    .autoSize{
        width: auto;
        height: auto;
        max-width: calc(100% - 5rem);
        max-height: calc(100% - 4rem);
    }
}