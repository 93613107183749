@import "src/assets/themes/misc.module.scss";

.filtersToggle{
    display: none;
}
@media (max-width: $mobile) {
    .hideMobile{
        display: none;
    }
    .filtersToggle{
        display: flex;
        button{
            width: 100%;
            background-color: none;
            border: none;
            padding: 0.4rem;
            font-weight: bold;
            display: flex;
            justify-content: space-between;
        }
    }
}

.epg
{
    display: flex;
    height: 100%;

    .filterHistoryWrapper{
        height: 100%; 
        overflow: auto;
        width: 19rem;
        min-width: 19rem;
        @media (max-width: $mobile) {
            width: 100%;
            min-width: 100%;
        }
    }
}