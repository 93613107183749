.cardWrapper{
    /* background-color:black; */
    /* padding: 1rem; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: calc(100% - 9.5rem);
    position: relative;
    overflow: auto;
}
.waterfallWrapper{
    position: relative;
    height: calc(100% - 0.4rem);
    padding-block-start: 0.4rem;
}
.status{
    stroke: black;
    stroke-width: 30;
    position: absolute;
    inset-inline-end: 1rem;
    inset-block-start: 1rem;
}
.statusGood{ color: #4CAF50; }
.statusBad{ color: #F44336; }