.tableContainer{
  display: flex;
  flex-direction: column;
  min-width: 50%;
  border-radius: 4px;
  max-height: 100vh;
  overflow-y: auto;
  width: 100%;
  min-height: 25rem;
  position: relative;

  .noFilterResultContainer{
    padding-inline-start: 2.5rem;
    font-size: 1rem;
  }  
  .table {
    width: 100%;
    max-height: 100vh;
    border-collapse: collapse;
    margin-bottom: 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 0.2rem;
    border: 1px solid lightgray;
    .filterIcon {
      cursor: pointer;
      transition: transform 0.3s ease-in-out;
      margin: 0 0 0 0.2rem;
      &:hover {
        color: #e0e0e0;
        transition: 0.2s ease-in-out;
      }
    }

    thead{
      font-weight: bold;
      position: sticky;
      top: 0rem;
      z-index: 99;
      background-color: #ffffff;
      padding: 0.5rem 0 0.5rem 0;
    }
  
    tbody {
      .tableRow{
        background-color: rgb(238, 238, 238);
        .firstColumn{
          display: flex;
          justify-content: space-between;
          width: 16rem;
        }
      }
      .tableRow:nth-child(even) {
        background-color: #ffffff;
      }
  
      .tableRow:hover {
        background-color: #e0e0e0;
        transition: background-color 0.3s ease-in-out;
        
        &.clickable{
          cursor: pointer;
        }
      }
    }

    th{
      padding: 0.6rem 0 0.6rem 16px !important;
      width: max-content;
    }
  
    th,
    td {
      padding: 16px;
      text-align: left;
      // border: 1px solid #ddd;
      //white-space: nowrap;
      .customCheckbox {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    .customCheckbox + label {
      position: relative;
      padding-left: 25px;
      cursor: pointer;
      font-size: 13px;
      user-select: none;
  }
  
  .customCheckbox + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 15px;
      height: 15px;
      border: 1px solid gray;
      background: white;
  }

  .customCheckbox:checked + label:before {
    background: #ffffff;
  }
  .customCheckbox:checked + label:after {
    content: '';
    position: absolute;
    left: 4px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid rgb(0, 0, 0);
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
    }
  
    .headersRow {
      background-color: #ffffff;
      color: #111111;
    }
  
    tr{
      font-size: 0.9rem;
    }
  
    @media (max-width: 1500px) {
      th {
        display: none;
      }
  
      .tableRow {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        margin-bottom: 4px;
        
  
        td {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #ddd;
          margin: 0;
          padding: 0 0.5rem 0 0.5rem;
  
          &:before {
            content: attr(data-label);
            font-weight: bold;
            display: inline-block;
            width: 50%;
            background-color: #23527c;
            color: #ffffff;
            padding: 0.5rem;
            min-width: 50%;
          }
        }
      }
    }
  }
  
}