.waterfallPlayer{
    display: flex;
    align-items: center;

    height: 100%;
    width: 100%;
}

@media only screen and (orientation: portrait)
{
    .waterfallPlayer
    {
        flex-direction: column;
    }
}

.videoContainer
{
    position: relative;
    margin: 0 .3rem;
    flex: 1 1 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.videoPlaceholder{
    width: calc(100% - 48px - 48px - 1rem);
    margin-inline-start: auto;
    margin-inline-end: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.buttonContainer{
    text-align: center;
}

.videoFailedText{
    text-align: center;
    margin-block-end: 2rem;
}

@media only screen and (orientation: portrait)
{
    .videoContainer
    {
        /* width: calc(100% - .6rem); */
        width: 100%;
        margin: .3rem;
    }
}

.topbar
{ 
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    width: 100%;
    height: 3rem;
    margin-block-end: .3rem;

    /* TODO: when theming branch, change to theme color!! */
    background-color: #23527c;
    border-radius: 4px;
    box-shadow: 0 10px 7px -5px #0003;
}
.topInfo
{
    margin-inline-end: .5rem;
    color: white;
}

.back
{
    color: white;
    width: 6rem;
}

.hitsContainer{
    overflow: auto;
    height: 100%;
    flex: 0 0 30rem;

    overflow-x: hidden;

    box-shadow: -5px 0 5px -5px #0003;
}

@media only screen and (orientation: portrait)
{
    .hitsContainer
    {
        width: 100%;
        flex: 1 1 150% !important;
    }
}

.arrow{
    position: absolute;

    height: 2rem;
    padding: 3rem .5rem;
    
    transform: translate(0, -50%);

    border-radius: 4px;
    background-color: #0001;
    transition: background-color .2s ease-in-out;
}
.arrow:hover{    
    background-color: #000A;

    cursor: pointer;

    transition: background-color .2s ease-in-out;
}

.arrowLeft{
    inset-block-start: 50%;
    inset-inline-start: 0.4rem;
}

.arrowRight{
    inset-block-start: 50%;
    inset-inline-end: 0.4rem;
}