.wrapper {
    margin: 0.7rem;

    .coursesOverview {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        margin-block-start: 1rem;

        .course {
            display: flex;
            flex-direction: column;
            width: 50%;
            margin-bottom: 1rem;
            justify-content: center;

            .content {
                display: flex;
                flex-direction: row;

                margin-bottom: .2rem;
                justify-content: center;
                align-items: center;

                .image {
                    height: 100px;
                }
                .description {
                    margin-left: 0.7rem;
                    margin-right: 0.7rem;
                    width: 189px;

                    h4{
                        margin-top: 0px;
                        margin-bottom: 0.4rem;
                    }
                }
            }
        }
    }
}