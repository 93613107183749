.groupcard-header
{
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 2rem;
    margin-block-start: 0.3rem;
    margin-block-end: 0.3rem;
}

.groupcard-edit
{
    background-color: #FFF0;
    
    border: none;
    border-radius: 5px;

    font-size: 1.2rem;

    margin-inline-end: .5rem;
    padding: .4rem .6rem;

    transition: .2s background-color ease-in-out;
}
.groupcard-edit:hover
{
    background-color: lightgray;

    transition: .2s background-color ease-in-out;
}

.groupcard-input
{
    background-color: #FFF0;
    margin-block-start: 0;

    border: none;
    border-block-end: 1px solid black;

    font-size: 1.2rem;

    min-width: 10rem;
    max-width: 20rem;
    width: auto;
}

.groupcard-controls
{
    margin-inline-start: .5rem;
}
.groupcard-controls > button
{
    border: 1px solid black;
    border-radius: 3px;

    font-size: 1rem;
    
    margin-inline-start: .5rem;
}

.groupcard-save
{
    background-color: #23527c;
    color: whitesmoke;
    border-color: #23527c !important;    
}

.groupcard-delete
{
    background-color: #F44336;
    color: whitesmoke;
    border-color: #F44336 !important;
}