.item {
  display: flex;
  margin: .3rem 0;
  padding: .3rem;
  
  background-color: #E0E0E0;
  border-radius: 4px;
  border: 1px solid lightgray;
  transition: .2s border-color ease-in-out;
}

.item.clickable:hover{
  border: 1px solid gray;
  transition: .2s border-color ease-in-out;
  cursor: pointer;
}

.entry {
  width: 0rem;
  flex: 0 0 10rem;
}