$primary: #575366;
$primary_disabled: rgb(87, 83, 102, 0.2);
$primary_reverse:#777483;
$secondary: #ed6c4e;
$success: #4CAF50;
$danger: #F44336;

$ci: (
  #f2b84a,
  #987139,
  #5b7388,
  #72899e,
  #e48853,
  #6c543a,
  #b9b39a,
  #3a5365,
  #293742,
  #e2a05f,
  #8d7c6a,
  #c19a68,
  #45525e,
  #d0a3a3,
  #a0785a,
  #f2e2ca
);

// use something like this to auto-calculate matching color ?
// https://jfelix.info/blog/how-to-make-a-text-color-fit-any-background-color
$ciFont:(#000000, #ffffff, #000000, #000000, #000000, #ffffff, #000000, #ffffff, #000000, #ffffff, #000000, #ffffff, #000000, #ffffff, #000000, #000000);

.font{
  // font-family: SomarSans, sans-serif;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

@import '../base.module.scss';