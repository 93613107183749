.wrapper 
{
    display: flex;
    align-items: center;

    padding-inline-start: .5rem;

    height: 2.5rem;

    background-color: none;
    cursor: pointer;

    transition: background-color .1s ease-in-out;

    border-inline-start-width: 2px;
    border-inline-start-style: solid;
    border-color: lightgrey;
}
.wrapper:hover
{
    background-color: white;
    color: #222;
    transition: all .1s ease-in-out;
}

.description
{
    margin-inline-start: .5rem;
}

.primary
{
    font-weight: bold;
}

.iconGroup
{
    display: flex;
    align-items: center;

    width: 4rem;
}

.typeIcon
{
    display: flex;
    justify-content: center;

    margin-inline-start: .5rem;
    width: 1.5rem;

    font-size: 1.1rem;
    text-align: center;
}

.selected
{
    background-color: #E0E0E0;
    transition: background-color .1s ease-in-out;
}