.settings{    
    width:20rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.category{
    background-color:lightgray;
    padding-inline-start:.3rem;
    padding-block-start:.3rem;
    padding-block-end:.3rem;
}
.center{
    display:flex;
    justify-content: center;
    align-items: center;
}

.radioButtons > label > input {
    margin: 0 .5rem;
}

.radioButtons > label {
    display: flex;
    align-items: center;

    cursor: pointer;
    background-color: #f4f4f4;
    color: #444 !important;

    margin-block-end: 0px !important;
    padding-block-start: 1rem !important;
    padding-block-end: 1rem !important;
}
.radioButtons.disabled > label {
    cursor: default;
}

.radioButtonsHorizontal {
    display: flex;
    justify-content: space-between;
}
.radioButtonsHorizontal > label {
    width: 49%;
}

.radioButtonContent{
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;

    border-color: rgb(224, 224, 224);
    border-radius: 1px;
    border-width: 1px;
    border-style: solid;
    border-block-start: 0px;
    border-block-end: 0px;
    background-color: transparent !important;
    min-width: fit-content;

}

.selectables{
    height: fit-content;
  
}

.settingsWrapper{
    height: 100%;
    overflow: auto;
}

.history {
    margin-block-start: 40px;
    height: 100%;
    overflow: hidden;
}

.lastX, .dateRangePicker{
    z-index: 9001;
}