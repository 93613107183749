@font-face {
    font-family: SomarSans;
    src: url(/assets/fonts/SomarSans/SomarSans-Regular.ttf) format("truetype");
    font-display: swap;
    font-weight: normal;
    font-style: normal;
  }
  
@font-face {
    font-family: SomarSans;
    src: url(/assets/fonts/SomarSans/SomarSans-RegularItalic.ttf) format("truetype");
    font-display: swap;
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: SomarSans;
    src: url(/assets/fonts/SomarSans/SomarSans-Bold.ttf) format("truetype");
    font-display: swap;
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: SomarSans;
    src: url(/assets/fonts/SomarSans/SomarSans-BoldItalic.ttf) format("truetype");
    font-display: swap;
    font-weight: bold;
    font-style: italic;
}

// --------------------------------------------------------------------------------------------

// @font-face {
//     font-family: TradeMarker;
//     src: url(/assets/fonts/TradeMarker/TradeMarker-Regular.ttf) format("truetype");
//     font-display: swap;
//     font-weight: normal;
//     font-style: normal;
//   }
  
// @font-face {
//     font-family: TradeMarker;
//     src: url(/assets/fonts/TradeMarker/TradeMarker-RegularItalic.ttf) format("truetype");
//     font-display: swap;
//     font-weight: normal;
//     font-style: italic;
// }

// @font-face {
//     font-family: TradeMarker;
//     src: url(/assets/fonts/TradeMarker/TradeMarker-Bold.ttf) format("truetype");
//     font-display: swap;
//     font-weight: bold;
//     font-style: normal;
// }

// @font-face {
//     font-family: TradeMarker;
//     src: url(/assets/fonts/TradeMarker/TradeMarker-BoldItalic.ttf) format("truetype");
//     font-display: swap;
//     font-weight: bold;
//     font-style: italic;
// }