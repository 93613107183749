.banner
{
    position: fixed;
    z-index: 1000000;
    inset-inline-start: 50vw;
    transform: translate(-50%, 0);

    display: flex;
    justify-content: center;
    align-items: center;

    padding: .5rem 1rem;
    min-width: 20rem;
    max-width: 40rem;
    min-height: 3rem;
    
    font-size: 1.2rem;
    color: whitesmoke;
    background-color: #424242;

    box-shadow: 0 0 3px 2px #0003;
    
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    animation-duration: .3s !important;
    transition: all .2s ease-in-out;
}
html[dir="rtl"] .banner{
  transform: translate(50%, 0);
}

.icon
{
  margin-inline-end: 1rem;
}

.close
{
  cursor: pointer;
}

.hidden
{
  transform: translate(-50%, -120%);
  transition: all .2s ease-in-out;
}
html[dir="rtl"] .hidden{
  transform: translate(50%, -120%);
}