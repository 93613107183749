.history {
    /* background: orange; */
    flex: row wrap;
    height: 100%;
    overflow: auto;
    width: 100%;
}

/* .history div:nth-child(even) {
    background: rgba(219, 36, 36, 0.527);
} */

/* .history div:nth-child(odd) {
    background: lightgray;
} */

.history-item {
    display: flex;
    justify-content: space-between;
    border-color: #ddd;
    border: 1px solid #ddd;    
}

.history-item-title {
    display: flex;
    flex: 1 1 auto;
    justify-content: space-around;
    gap: 0.5rem;
}

.arrow, .cross, .history-item-title{
    cursor: pointer;
    padding: .8rem;
    color: rgba(0,0,0,0.65);
}
.arrow:hover, .cross:hover, .history-item-title:hover{
    cursor: pointer;
    color: rgba(0,0,0,1);
}
.history-item.opened{
    background-color: rgba(245, 130, 33, 0.9);
}
.history-item.opened .arrow, .history-item.opened .cross, .history-item.opened .history-item-title{
    color:rgba(255,255,255,1);
}
.history-item.opened .arrow:hover, .history-item.opened .cross:hover, .history-item.opened .history-item-title:hover{
    color:rgba(0,0,0,1);
}

.history-item.disabled{
    *{
        cursor: default;
    }
    opacity: 0.5;
    &:hover, &:hover .arrow, &:hover .cross, &:hover .history-item-title {
        color: rgba(0,0,0,0.65);
    }
}