#root {
    /* width: 100vw; */
    height: 100vh;
    overflow: hidden;
}

#board {
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 100vh;
}

h2 {
    margin: 0;
    font-size: 1.5rem;
}

section {
    width: 100%;
}

button 
{
    cursor: pointer;
}

input:focus, button:focus
{
    outline: none;
}

.embed-wrapper
{
    height: 100%;
    overflow: auto;
}


/* TOOD: find a way to move this to ReportElement.module.scss ? */
/* used for Pptxgen.js png export */
/* .export-wrapper .recharts-legend-item-text{
    font-size: 50px;
} */
/* .export-wrapper svg.recharts-surface tspan{
    font-size: 20px;
} */
.export-wrapper .recharts-legend-item > .recharts-surface{
    width: 20px;
    height: 20px;
}
/* .export-wrapper .recharts-layer > text{
    margin-inline-start: 10rem;
} */

//ResizeObserver loop completed with undelivered notifications error on Chrome Fix / Analytics section
iframe#webpack-dev-server-client-overlay{display:none!important}
