.user-input {
    border: none;
    border-block-end: 1px solid black;
    font-size: 1.2rem;
    margin-block-start: .5rem;
}

.user-select {
    margin-block-start: .5rem;
}

.user-datepicker-with-label {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;
    z-index: 10;
}

.user-datepicker {
    margin-block-start: .5rem;
}

.checkboxes-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 1rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    .checkboxItem{
        display: flex;
    }
}

.user-archive-container{
    display: flex;
    align-items: center;
    width: 100%;
    gap: 0.5rem;
}

.user-datepicker>div {
    width: 15rem;
}
.user-datepicker-archive{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    z-index: 999;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.archiveDays-input{
    width: fit-content;
   input{
    width: 11rem;
    min-height: 2.2rem !important;
   }
}

.archive-radio{
    width: 2rem;
    margin-left: 2rem;
}

.datepicker-archive{
    height: 100%;
    margin-top: 1rem;
}

.error-message {
    color: red;
    font-size: 1rem;
    margin-block-start: .5rem;
}

.error-message-comment {
    color: red;
    font-size: 1rem;
    margin-block-start: -0.2rem;
    position: absolute;
    top: 0;
    right: 1rem;
}

.user-comment {
    position: relative;
}