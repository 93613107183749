.mentionsVisualizerContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-inline-start: 2rem;
    padding-inline-end: 2rem;
}

.profileGroupSelection{
    display: flex;
    gap: 1rem;
    height: fit-content;
    justify-content: center;
    width: 100%;
    padding: 0.5rem;
    background-color: #f7f7f7;
    .selectProfileGroups{
        width: 50%;
        &:hover{
            cursor: pointer;
            background-color:#dbdbdb;
        }
    }
}

.noDataMessage{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    margin-block-start: 15rem;
    width: 100%;
    font-size: 1.5rem;
    color: #b3b3b3;
}

.loadingSpinnerContainer{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-block-start: 10rem;
    width: 100%;
}
