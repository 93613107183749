.channelGroupsContainer{
    display: flex;
    height: 100%;
    gap: 1rem;
    @media(max-width: 1280px){
        width: 100%;
        flex-wrap: wrap;
    }

    .editViewContainer {
        display: flex;
        flex-direction: column;
        margin: auto;
        width: 100%;
        height: 100%;
        position: absolute;
        align-items: center;
        justify-content: center;
        z-index: 1000;
        background-color: white;
        @media(max-width: 864px){
            width: 100%;
            padding-inline: .5rem;
        }
        .editViewHeader {
            display: flex;
            justify-content: space-between;
        }
        .groupEditInput {
            width: 100%;
        }
        .headerLeft {
            display: flex;
            align-items: center;
            gap: 0.6rem;
            font-size: 1.5rem;
            font-weight: bolder;
            &:hover {
                cursor: pointer;
            }
        }
        .middleSection{
            width: 40rem;
            .duplicateInput{
                padding-inline-end: 0.5rem;
            }
        }
    }
}