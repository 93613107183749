.profileGroupTitle{
    background-color: #f7f7f7;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    padding-top: 1rem;
    width: 100%;
    position: sticky;
    text-align: center;
    text-transform: uppercase;
    font-size: 26px;
    font-weight: 500;
    letter-spacing: 1px;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: 16px 0;
    grid-gap: 22px;
    &:before, &:after{
        content: "";
        display: block;
        border-bottom: 2px solid #ccc;
        background-color: #f8f8f8;
    }
}

.downloadIcons{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem
}

.backFillIcon{
    font-size: 1.2rem;
    display: flex;
    gap: 0.2rem;
    align-items: center;
    color: #F44336;
    &:hover{
        cursor: pointer;
        color: #ff5f52;
        transition: ease-in-out 0.3s;
    }
}

.chartIcon{
    font-size: 1.4rem;
    display: flex;
    gap: 0.2rem;
    align-items: center;
    color: #23527c;
    position: relative;
    height: 3rem;
    &:hover{
        cursor: pointer;
        color: #4da6ff;
        transition: ease-in-out 0.3s;
    }
    
}

.chartTooltip{
    position: absolute;
    background-color: #ffffff;
    color: #2c2c2c;
    padding: 0.5rem;
    border-radius: 0.5rem;
    font-size: 0.8rem;
    top: 1rem;
    right: 0.5rem;
    z-index: 1;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.xlsxDownloadIcon{
    font-size: 1.4rem;
    display: flex;
    gap: 0.2rem;
    align-items: center;
    color: #09a65e;
    height: 3rem;
    &:hover{
        cursor: pointer;
        color: #26d38b;
        transition: ease-in-out 0.3s;
    }
}

.rssDownloadIcon{
    font-size: 1.4rem;
    display: flex;
    gap: 0.2rem;
    align-items: center;
    color: #ff6600;
    height: 3rem;
    &:hover{
        cursor: pointer;
        color: #ff9751;
        transition: ease-in-out 0.3s;
    }
}

.foldDuplicatesIcon {
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    color: #23527c;
    position: relative;
    width: max-content;
    &:hover{
        cursor: pointer;
        color: #4da6ff;
        transition: ease-in-out 0.3s;
    }

}

.profileTitlesContainer{
    display: flex;
    gap: 1rem;
    width: 100%;
    align-items: center;
    margin-block-end: 0.3rem;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    justify-content: space-between;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
    z-index: 99;
    background: white;
    overflow-x: auto;
    overflow-y: hidden;
    min-height: 3rem;
    cursor: grab;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    &:active {
        cursor: grabbing;
    }
    .profileTitles{
        display: flex;
        width: 90%;
        overflow: auto;
        
    }
    .profileTitleButton{
        z-index: 99;
        border: none;
        background-color: #f7f7f7;
        border-radius: 0.5rem;
        font-size: 1rem;
        height: 2rem;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
        &:hover{
            cursor: pointer;
            background-color:#dbdbdb;
        }
    }
}