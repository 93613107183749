.switchLabel
{
    display: flex;
    align-items: center;

    margin: .5rem;

    height: 1rem;

    cursor: pointer;
}

.disabled
{
    cursor: default;
}

.switch
{
    margin-inline-end: .5rem;
}

.switchLabelLeft {
    margin-inline-start: .5rem;
}