.wrapper {
    height: calc(100% - 3rem);
}
.numbers {
    padding-inline-start: .5rem;
    font-weight: bold;
}
.cardsHeadWrapper{
    display: flex;
    align-items: center;
}