.facet{
    display: flex;
    justify-content: space-between;
    border-block-end: 1px solid gray;
    padding-block-start: .2rem;
    padding-block-end: .2rem;
    padding-inline-start: .3rem;
    padding-inline-end: .3rem;
    cursor: pointer;
    color: black;
}
.nonSelectedFacet:hover{
    background-color: #dddddd59;    
}