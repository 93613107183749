.clipsContentContainer{
    display: flex;
    overflow: hidden;
    flex-direction: column;
    flex: 0.86;
    @media (max-width: 864px){
        order: 2;
    }
    //overflow-y: auto;
    //justify-content: center;
}

.clipsContentContainerBackFill{
    display: flex;
    overflow: hidden;
    flex-direction: column;
    flex: 0.86;
    background-color: rgb(255, 253, 247);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    border: 0.2rem solid rgb(243, 239, 227);
    @media (max-width: 864px){
        order: 2;
    }
    //overflow-y: auto;
    //justify-content: center;
}

.backFillButton{
    height: fit-content;
}

.backFillMessage{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 1rem;
    color: #23527c;
    padding-inline-end: 1rem;
}

.selectedCount{
    font-size: 1rem;
    color: #23527c;
    font-weight: 500;
}

.selectedCountNotAllSelected{
    display: none;
}

.selectAllIcon{
    font-size: 1.2rem;
    color: #23527c;
}

.backFillSelectContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-block-start: 0.5rem;
    padding-block-end: 0.5rem;
    height: 2rem;
    .backFillButtons{
        display: flex;
        gap: 0.5rem;
    }
}

.backFillButtons{
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.movedItemsMessage{
    font-size: 1rem;
    color: #23527c;
}

.backFillSelectAll{
    display: flex;
    align-items: center;
    gap: 0.2rem;
    font-size: 1rem;
    cursor: pointer;
    &:hover{
        color: #23527c;
    }
}

.progressMessage{
    font-size: 1rem;
    color: #23527c;
}