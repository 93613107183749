.companyEditContainer {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0.5rem 0 0.5rem;
    height: 100%;
    gap: 0.5rem;
    .datePickers{
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        .companyDatePickerWithLabel {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 2rem;
        
            > div{
          
                width: 14rem;
                
            }
            .companyDatePicker{
                height: 2.4rem;
                margin-block-start: 1rem;
            }
            .contractDatePicker{
                height: 2.4rem;
                margin-block-start: 1rem;
                margin-inline-start: 2rem;
            }
        }

        .select {
            /* margin-block-start: .5rem; */
            border: 1px solid grey;
            border-radius: 4px;
            width: 100%;
            >div{
                &:first-child{
                    border: none !important;
                }
            }
        }
    }
}

.paymentContainer{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.timeContainer{
    display: flex;
    align-items: center;
}

.sharegroupsWrapper{
    height: calc(100% - 3rem);
}