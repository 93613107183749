.player{
    width: 100%;
    height: calc(100% - 3.8rem);

    box-shadow: 0 10px 7px -5px #0003;
    border-radius: 4px;

    background-color: #212121;
}

@media only screen and (orientation: portrait)
{
    .player
    {
        height: calc(100% - 2.8rem);
    }
}