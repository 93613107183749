.inputGroup {
    
    width: 100%;
    
    /* margin: .5rem 0; */

    position: relative;
}

.input:disabled {
    background-color: #ECEFF1;
    color: rgb(109, 109, 109);
}

.disabled {
    color: rgb(109, 109, 109) !important;
}

.input {
    border: none;
    border: 1px solid grey;
    font-size: 1rem;

    padding: 0;
    padding-inline-start: .5rem;
    padding-inline-end: 3rem;
    border-radius: 4px;

    /* height: 100%; */
    min-height: 36px;
    width: calc(100% - 3.6rem);
}

.input.invalid {
    border-width: 2px;
}

.inputWithIcon {
    position: relative;
    display: inline-flex;
    align-items: center;
}

.iconContainer {
    position: absolute;
    padding-inline-start: 1rem;
}

.area {
    padding-block-start: .5rem;
    padding-block-end: .2rem;
    resize: none;

    font-family: inherit;
}
.area:focus {
    outline: none;
}

/* designed to be on the right side */

.textfieldLabel {
    font-size: .8rem;
    font-weight: bold;

    text-align: right;
    
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0.2rem;

    .labelRight{
        font-weight: 400;
    }
}