.table{
    border-collapse: collapse;
    
    th{
        padding: 0.5rem;
        border-block-end: 1px solid black;

        &:not(:last-of-type){
            border-inline-end: 1px solid black;
        }
    }

    td{
        padding-inline-start: 0.2rem;
        padding-inline-end: 0.2rem;

        &:not(:last-of-type){
            border-inline-end: 1px solid black;
        }
    }

    tr:not(:last-of-type){
        border-block-end: 1px solid black;
    }
}