.filterboxContainer{
    display: flex;
    flex-direction: column;
    background-color: #e8ebee;
    height: 20rem;
    gap: 0.4rem;
    position: absolute;
    z-index: 99;
    width: 12rem;
    font-weight: 400;
    font-size: 0.9rem;
    padding-inline-start: 0.2rem;
    padding-inline-end: 0.2rem;
    padding-block-start: 0.2rem;
    padding-block-end: 0.2rem;
    @media(max-width: 1341px){
        height: 15rem;
        width: 10rem;
        gap: 0.2rem;
    }
}

.lastBoxPosition{
    position: absolute;
    right: 0;
}

.wrapper{
    padding-top: 0.5rem;
}
  