@mixin flexContainer($direction, $justify, $align) {
    display: flex;
    flex-direction: $direction;
    justify-content: $justify;
    align-items: $align;
}

.videoCardContainer {
    box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
    width: fit-content;
    padding: 1rem;
    gap: 0.5rem;
    height: fit-content;

    @include flexContainer(column, space-evenly, center);

    .videoCardHeader {
        @include flexContainer(row, space-between, center);
        width: 100%;

        .iconWrapper {
            @include flexContainer(row, space-between, center);
            gap: 0.5rem;
            width: 100%;

            .channelIcon {
                border-radius: 50% !important;
                width: 2rem;
                height: 2rem;
                object-fit: contain;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            }
        }

        .channelName {
            font-weight: 500;
            width: 100%;
        }
    }

    .videoPlayerSection {
        display: flex;
        justify-content: center;
        width: 100%;
        position: relative;

        .thumbnail {
            object-fit: contain;
            border-radius: 0.2rem;
        }

        .playIcon {
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translate(50%, -50%);
            font-size: 3rem;
            color: #fafafa;

            &:hover {
                color: #cecece;
                transition: 200ms ease-in-out;
                cursor: pointer;
            }
        }
    }
    .beginF{
        font-size: 1.2rem;
        letter-spacing: 0.05rem;
    }
}
