.delete-row
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .2em;
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    margin-block-start: .5rem;         
}

.delete-row button
{
    margin: 0;
}

.removeGroupContainer{
    display: flex;
    flex-direction: column;
    position: relative;
    height: fit-content;
    @media (max-width: 864px){
        width: 100%;
    }
    >h3{
        margin: 0;
    }
    >p{
        margin: 0.5rem;
    }
}