.tableAreaContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-end;
    margin-inline-end: 1rem;
}

.addRemovePopupContainer{
    display: flex;
    flex-direction: column;
    position: relative;
    height: fit-content;
    gap: 0.5rem;
    @media (max-width: 1384px){
        width: 100%;
    }
    >h3{
        margin: 0;
    }
    >p{
        margin: 0.5rem;
    }
    .selectBox{
        margin: 0.5rem 0 0.5rem 0;
        
    }
}

.popup{
    background-color: red;
}