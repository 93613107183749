.wrapper
{
    margin-block-end: 1rem;
}

.filter
{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;

    width: 100%;
    min-height: 3rem;

    padding: .3rem 0;

    /* margin-block-end: 1rem; */
}

.filter > * {
    margin-inline-start: 0.2rem;
    margin-inline-end: 0.2rem;
    margin-block-start: 0.2rem;
    margin-block-end: 0.2rem;
}

.filter > .profileSelect, .filterSelect, .dateRangeWrapper{
    flex: 1 1 auto;
}

.profileSelect
{
    min-width: 185px;
    height: 2.3rem;
    /* max-height: 2.3rem; */

    z-index: 12;
}
.filterSelect {
    min-width: 280px;
    z-index: 12;
}
.profileSelect > div:first-child > div
{
    max-height: 2.3rem !important;
    overflow: auto;
}
.profileSelect > div:first-child
{
    max-height: 2.3rem;
}

.switchPageWrapper
{
    flex: 0 0 auto;

    /* height: 100%; */
    height: 2.2rem;

    overflow: hidden;

    border: 1px solid grey;
    border-radius: 4px;
}

.switchPage
{
    font-size: 1.5rem;
    width: 4rem;
    min-width: 90px;
    height: 2.2rem;
 
    margin: 0;
    
    border-radius: 0;

    border: 0;
    background-color: white;
    color: black;
}

.dateRangeWrapper {
    min-width: 12rem;
    max-width: 300px;
    z-index: 1;
}

.whiteText
{
    color: white;
}

.switchPage:not(:first-child)
{
    border-inline-start: 1px solid grey;
}

.datepicker
{
    height: 2.3rem;
    z-index: 11;
}
.datepicker > div
{
    border: 1px solid grey;
    border-radius: 4px;
}