.notification-card
{
    display: flex;

    margin: .2rem;

    width: calc(100% - .4rem);
    min-height: 3rem;

    border: 0 transparent solid;
    border-radius: 5px;
    background-color: #ECEFF1;

    animation-duration: .4s;
    transition: all .15s ease-in-out;
}
.notification-card:hover
{
    background-color: #E0E0E0;
    transition: all .15s ease-in-out;
}

.notification-content
{
    flex: 2 1 auto;
    margin: .3rem;

    cursor: pointer;
}

.notification-closer
{
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 1.5rem;
    
    text-align: center;
    font-size: 1.5rem;
    
    cursor: pointer;

    border: 0 transparent solid;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    color: #0001;
    background-color: #0002;

    transition: background-color .1s ease-in, color .1s ease-in;
}
.notification-closer:hover
{
    color: whitesmoke;
    background-color: #4CAF50;

    transition: background-color .1s ease-out, color .1s ease-out;
}
.notification-closer:focus
{
    outline: none;
}