.weekdays {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
// .labeledSwitch {
//     width: 5rem;
//     margin-inline-start:0;
//     margin-inline-end:0.8rem;
//   }
.schedule {
    padding: 1rem;
    height: calc(100% - 2rem);
    max-width: 900px;

    .currentSchedule{
        height: calc(100% - 5rem);
        display: flex;
        flex-direction: column;
    }

    .tasks {
        max-height: calc(100% - 11.5rem);
        overflow: auto;

        .task {
            background-color: rgba(236, 239, 241, 0.533);
            border-radius: 4px;
            padding: 0.4rem;
            margin-block-end: 0.3rem;
            display: flex;
            justify-content: space-between;
            align-items:center;

            .repeat {
                display: inline-flex;
                margin-inline-end: 0;
            }

            .timeOptions {
                display: flex;
                align-items: center;
                & > * {
                    padding-inline-end: 0.5rem;
                }
            }
            .repeatUnit {
                width: 200px;
            }
            .repeatNumber {
                width: 3rem; 
                
                & > * {
                    width: 3rem;
                    padding:0;
                    text-align: center;
                }
            }
        }
    }
    .task.disabled {
        opacity: 0.3;
        cursor: pointer;
    }

    & > *, .select, .input {
        margin-inline-end: 1.5rem;
        margin-block-end: 1rem;
    }
    .weekdays {
        margin-block-end: .5rem;
    }

    .select {
        min-width: 300px;
        max-width: calc(100% - 20rem);
        margin-block-start: 0.35rem;
    }

    .options button {
        margin-inline-end: .6rem;
        margin-block-end: .6rem;
    }
}