.academyLogo {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    margin-inline-end: 1.5rem;
    img {
        width: 280px;
    }
}

@media only screen and (max-width: 1120px) {
    .academyLogo {
        img {
            width: 150px;
        }
    }
}

@media only screen and (max-width: 965px) {
    .academyLogo {
        display: none;
    }
}

.menuTourHelper {
    box-shadow: none;
}

.dropUser {
    width: 15.3rem;
    @media screen and (max-width: 650px) {
        width: calc(100% - 3.5rem);
        padding-inline-end: 0.4rem;
    }
}

.dropHelp {
    width: 18.25rem;
    @media screen and (max-width: 650px) {
        width: calc(100% - 3.5rem);
        padding-inline-end: 0.4rem;
    }
}

.userName {
    font-size: 1rem;
    /* width: 10rem; */
    padding: 0.3rem;
    overflow: hidden;
    text-overflow: ellipsis;
    border-block-end: 1px solid transparent;

    transition: all 0.2s eas-in-out;
    &:hover {
        border-block-end: 1px solid black;

        transition: all 0.2s ease-in-out;
    }
    @media only screen and (orientation: portrait) and (max-width: 650px) {
        display: none !important;
    }
}

.userIcon {
    margin: 0 0.5rem;
    font-size: 2.8rem;
    justify-self: flex-end;
}

.userMenuLink {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    margin-top: 0.1rem;
    width: 100%;
    height: 2rem;

    padding-inline-start: 0.5rem;
    padding-block-end: 0.5rem;

    text-decoration: none;
    color: whitesmoke;
    background-color: #fff0;

    border: none;

    cursor: pointer;
    text-align: left;
    font-size: 1rem;

    transition: all 0.1s ease-in-out;
}
.userMenuLink:hover {
    border-inline-start-width: 3px;
    border-inline-start-style: solid;

    transition: all 0.1s ease-in-out;
}
.userMenuLink > svg {
    margin-inline-end: 0.5rem;
    @media screen and (max-width: 650px) {
        font-size: 1rem;
    }
}

.userGuideSymbolV3 {
    width: 1rem;
    margin-inline-end: 0.6rem;
}

.user {
    display: flex;
    align-items: center;
    /* width: 15rem; */
}

.userInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: whitesmoke;
    height: 4rem;
    padding-inline-start: 0.3rem;
    cursor: pointer;
    width: 15rem;
    margin-inline-start: 0.2rem;
    @media (max-width: 650px) {
        justify-content: center;
        width: fit-content;
        margin-inline-end: 0.75rem;
        padding-inline-start: unset;
    }
}

.titlebarTitle {
    font-weight: normal;
    display: flex;
    align-items: center;

    overflow: hidden;
    height: 3rem;
    /*     padding: .5rem 0; */

    margin-block-start: 0;
    margin-inline-end: 0.7rem;

    @media only screen and (orientation: portrait) {
        padding-block-start: 0;
    }

    > span {
        padding-inline-start: 1rem;

        font-weight: bold;
        font-size: 1.3rem;

        overflow: hidden;

        height: 1.6rem;

        align-self: center;

        @media (max-width: 650px) {
            padding-inline-start: 0rem;
            padding-block-start: 0.5rem;
        }

        @media only screen and (orientation: portrait) and (max-width: 350px) {
            font-size: 1rem;
            align-self: baseline !important;
        }
    }

    > input {
        font-weight: bold;
        font-size: 1.3rem;
        margin-inline-start: 1rem;
        margin-block-start: 0;

        border: none;
        border-block-end: 2px solid black;

        width: 15rem;

        &:focus {
            outline: 0;
        }
    }
}

.exportButton {
    @media screen and (max-width: 650px) {
        font-size: 1rem;
        padding: 0.2rem 0.3rem 0.2rem 0.3rem;
        height: fit-content;
        border-radius: 0.2rem;
    }
}

.hamburgerMobile {
    height: 0.2rem;
    width: 1.8rem;

    &::after {
        height: 0.2rem;
        width: 1.8rem;
    }

    &::before {
        height: 0.2rem;
        width: 1.8rem;
    }
}

.helpIcon {
    display: flex;
    align-items: center;
    color: whitesmoke;
    height: 4rem;
    cursor: pointer;
    justify-self: flex-end;

    margin-inline-start: 1rem;
    margin-inline-end: 1rem;
    font-size: 1rem;
}

.helpMenu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: whitesmoke;
    height: min-content;
    cursor: pointer;
}

.burgerMenu {
    display: none !important;
    height: 3.8rem;
    width: 4rem;
    margin-block-start: 0.2rem;
    vertical-align: middle;
    &:focus {
        outline: none;
    }
    @media (max-width: 1080px) {
        display: inline !important;
    }
}

.notificationBellUnread
{
    border-radius: 100%;
    
    animation-duration: .8s;
    animation-delay: 1s;
}

.panelWrap
{
    position: fixed;
    inset-block-start: 0;
    inset-inline-start: 0;

    width: 100%;
    height: 100%;

    z-index: 9;
    @media(max-width: 650px){
        display: flex;
        justify-content: center;
    }
}

.notificationsPanel
{
    background-color: white;

    position: absolute;
    inset-block-start: 4rem;
    inset-inline-end: 0;
    
    border-inline-start-width: 3px;
    border-inline-start-style: solid;

    box-shadow: -1px 10px 5px 1px #0003;

    width: 30rem;
    height: calc(100vh - 4rem);

    z-index: 10;

    overflow: hidden;

    animation-duration: .3s;
}

.notificationBell
{
    font-size: 1.7rem;
    margin-inline-end: 10px;

    cursor: pointer;

    transition: all .2s ease-in-out;
    &:active
    {
        font-size: 1.6rem;   
    
        transition: all .2s ease-in-out;
    }
}

.header
{
    width: 100%;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    box-shadow: 0 5px 10px -10px #0003;

    position: relative;
    z-index: 10;

    background-color: white;
    color: black;
    @media only screen and (orientation: portrait)
{   
        height: 4rem !important;
}

    .menuPanel{
        height: 100%;
        display: flex;
        align-items: center;
    }

    > div
{
    display: flex;
}
}


.userMenu
{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;

    inset-block-start: 4rem;
    inset-inline-end: 0;

/*     width: 10rem; */
    // height: 7rem;
    animation-duration: .3s;

    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    @media (max-width: 650px) {
        padding: 1rem;
        inset-inline-end: unset;
        border-start-start-radius: 5px;
        border-end-start-radius: 5px;
        border-end-end-radius: 5px;
        border-start-end-radius: 0;


    }
    // @at-root [dir="rtl"] {   // NOTE: seems like this is not working as I expected "if root element (<html>) has dir=rtl, 
    // then apply rules to current item (.user-menu):
    //     &{
    //         inset-inline-start: 0;
    //         inset-inline-end: unset;
    //     }
    //   }
}

.gravatar{
    border-radius: 50%;
    margin: 0 0.5rem;
}

.mobileUserName{
    text-decoration: none;
    color: #ffffff;
    padding: 1rem;
    cursor:default;
}
// html[dir="rtl"] .user-menu{
//     inset-inline-start: 0;
//     inset-inline-end: unset;
// }

// .help-menu-link
// {
//     display: flex;
//     /* justify-content: space-between; */
//     align-items: center;

//     width: 100%;
//     height: 2rem;
//     margin: 0;
//     padding: 0rem;
//     padding-inline-start: .5rem;
//     padding-block-end: 0.5rem;
    
//     text-decoration: none;
//     color: whitesmoke;
//     background-color: #FFF0;
    
//     border: none;
    
//     cursor: pointer;
//     text-align: left;
//     font-size: 1.2rem;

//     transition: all .1s ease-in-out;
// }
// .help-menu-link:hover
// {
//     border-inline-start-width: 3px;
//     border-inline-start-style: solid;

//     transition: all .1s ease-in-out;
// }
// .help-menu-link > svg
// {
//     margin-inline-end: .5rem;
// }
