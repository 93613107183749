.groupcards-wrapper
{
    position: relative;

    width: 100%;
    height: 100%;
}

.groupcards
{
    width: 100%;
    height: calc(100% - 4rem);
    overflow-y: auto;

    display: flex;
    align-content: flex-start;
    justify-content: center;
    flex-wrap: wrap;

    padding-block-start: 1rem;
}

.groupcard-unopened
{
    display: flex;
    justify-content: space-between;

    width: calc(100% - 2rem);
    background-color: #ECEFF188;
    border: 1px solid lightgray;
    border-radius: 4px;

    margin: .25rem;
    padding: .5rem;

    font-weight: bold;

    transition: .2s border-color ease-in-out;
}
.groupcard-unopened:hover
{
    border-color: gray;
    transition: .2s border-color ease-in-out;
    cursor: pointer;
}

.groupcards-nosearch
{
    height: calc(100% - 1.3rem);
}

.groupcards-nosearch.groupcards-paginated
{
    height: calc(100% - 4.4rem);
    margin-block-end: .3rem;
}

.groupcards-nofound
{
    padding-block-start: 2rem;
    text-align: center;
}

.groupcards-back
{
    position: absolute;
    inset-block-end: .5rem;
    inset-inline-start: .3rem;
}

.groupcards-listtoggle
{
    position: absolute;
    inset-block-end: .5rem;
    inset-inline-end: .3rem;
}

.groupcard-content-list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.add-button-wrapper {
    position: absolute;
    bottom: 2rem;
    z-index: 99;
    display: flex;
    right: 2rem;
}
