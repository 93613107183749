.wrapper{
    display: flex;
    height: 98%;

    .badges{
        display: flex;
        align-items: center;
        height: 100%;
        width: 300px;
        margin-left: 1rem;
        margin-right: 1rem;
    }
}