.wrap
{
  display: flex;
  flex-direction: column;
  
  width: calc(100% - 4px);
  border: 2px solid #ECEFF1;
  border-radius: 4px;

  margin-block-start: .5rem;

  /* overflow: hidden; */
  /* z-index: 1; */
}

.header
{
  background-color: #ECEFF1;
  font-size: 1.1rem;

  padding: .5rem 0;

  cursor: pointer;
}

.headerIcon
{
  margin: 0 .5rem;

  font-size: 1.2;
  font-weight: bold;
}

.content
{
  padding: .3rem;
  opacity: 1;

  transition: opacity .3s ease-in-out;
}

.hidden
{
  height: 0;
  padding: 0;
  opacity: 0;

  transition: opacity 0s ease-in-out;
  z-index: -1;
}