.groupcard-item
{
    display: flex;
    align-items: center;

    height: 40px;

    background-color: #E0E0E0;

    border-radius: 100px;
    /* border-top-right-radius: 70px;
    border-bottom-right-radius: 70px; */

    margin: .3rem;

    transition: .1s background-color ease-in-out;
}
.groupcard-item-clickable:hover
{
    cursor: pointer;
    background-color: #BDBDBD;
    
    transition: .1s background-color ease-in-out;
}

.groupcard-item-secondary
{
    background-color: white;

    border: 1px solid #222;
    border-inline-start: none;
}

.groupcard-item-content
{
    margin: 0 1rem 0 .5rem;
}

.groupcard-item-disabled
{
    color: gray;
}

.groupcard-item-highlighted
{
    /* box-shadow: 0 0 5px #f5822166; */
    border: 1px solid #f58221;
    background-color: white;
}