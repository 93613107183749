$primary: #23527c;
$primary_disabled: rgba(35, 82, 124, 0.5);
$primary_reverse:#005F8F;
$secondary: #c4a01b;
$success: #4CAF50;
$danger: #F44336;
$ci:    (#ff9300, #304f72, #81a9c4, #AEAE5C, #F9B546, #753822, #987139, #003855, #CBD5DF, #022938, #CD9D81, #cf6522, #918C2F, #41718D, #a8bdc7, #cbd5df);
$ciFont:(#000000, #ffffff, #000000, #000000, #000000, #ffffff, #000000, #ffffff, #000000, #ffffff, #000000, #ffffff, #000000, #ffffff, #000000, #000000);

.font{
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

@import '../base.module.scss';