.sortButton{
    height: fit-content;
    display: flex;
    justify-content: center;
    padding: 0.5rem;
    flex-direction: column;
    gap: 0.3rem;
    cursor: pointer;
    .selected{
        text-align: left;
        font-weight: 500;
        font-size: 0.85rem;
        background-color: #0E65E8;
        color: #ffffff;
        transition: 300ms ease-in-out;
        border-radius: 0.5rem;
        padding: 0.2rem; 
        border: 1px solid lightgrey;
    }
    .nonSelected{
        text-align: left;
        font-weight: 500;
        font-size: 0.85rem;
        transition: 300ms ease-in-out;
        border-radius: 0.5rem;
        padding: 0.2rem;
        border: 1px solid lightgrey;
        &:hover{
            background-color: #e2e2e2;
        }
    }
}