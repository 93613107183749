.queryVisualization{
    background-color: white;
}
.op-and, .op-notAnd{
    display: block;
}
.and, .notAnd {
    vertical-align: top;
    display: inline-block;
    padding: 4px;
    margin: 6px;
    border: 3px solid;
    /* border: 3px solid darkred; */
    text-align: center;
}
.or, .notOr {
    vertical-align: top;
    display: inline-block;
    padding: 4px;
    margin: 6px;
    border: 3px solid;
    /* border: 3px solid green; */
    text-align: center;
}
.word {
    vertical-align: top;
    display: inline-block;
    padding: 4px;
    margin: 6px;
    border: 3px solid gray;
    text-align: center;
    background-color: lightgray;
}
.phrase {
    vertical-align: top;
    display: inline-block;
    margin: 6px;
    border: 3px solid gray;
    text-align: center;
    background-color: lightgray;
}
.phraseWord{
    padding: 4px;
    display: inline-block;
}
/* .notPhrase {
    vertical-align: top;
    display: inline-block;
    padding: 4px;
    margin: 6px;
    border: 3px solid gray;
    text-align: center;
    background-color: lightgray;
} */
.not {    
    border: 3px solid black;
}
.op {
    text-align: center;
    padding: 4px;
    margin: 6px;
}

.vocabIncluded {
    background-color: rgb(175, 250, 185);
}

.vocabMissing {
    background-color: rgb(255, 114, 114);
}

.vocabRequested {
    background-color: rgb(255, 209, 117);
}