.clipClusterContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 35rem;
    height: calc(100% - 6rem);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    background-color: #ffffff;
    position: relative;
    border-radius: 0.5rem;
    padding: 0.5rem 2rem 0.5rem 2rem;
    @media (max-width: 864px){
        width: 100%;
        min-width: 100%;
    }
    
    .clusterMiddleContainer{
        display: flex;
        justify-content: center;
        gap: 1rem;
        width: 100%;
        height: calc(100% - 9rem);
        padding: 1rem;
        border-radius: 0.5rem;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
        @media (max-width: 864px){
            flex-direction: column;
            height: 100%;
        }
    }
    
    
    .clusterFilters{
        position: absolute;
        top: 5rem;
        display: flex;
        font-weight: 500;
        justify-content: space-around;
        width: calc(100% - 4rem);
        background-color: rgb(247, 247, 247);
        border-radius: 0.2rem;
        box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
    }

    .checkboxWrapper{
        font-size: 0.8rem;
    }

    .checkboxSquare{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.5rem;
        height: 1.5rem;
        border: 1px solid #000000;
        border-radius: 0.2rem;
        cursor: pointer;
        &:hover{
            background-color: #dbdbdb;
        }
    }
}


