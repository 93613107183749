.rssFeedContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    width: calc(100% - 3rem);
    height: calc(100vh - 4rem);
    margin: 0 auto;
    overflow: auto;
}

.copyMessage {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
}