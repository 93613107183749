.clipFilterSelectContainer {
  display: flex;
  flex-wrap: wrap;
  background-color: #f5f5f5;
  justify-content: space-between;
  align-items: center;
  //box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  //z-index: 9981;
  padding: 1rem 0.5rem 0.5rem 0.5rem;
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  gap: 1rem;
  width: calc(100% - 1rem);
  //animation: slideInFromLeft 1s ease-in-out forwards;
  @media  screen and (max-width: 768px) {
    flex-direction: row;
    position: static;
  }
  // @keyframes slideInFromLeft {
  //   from {
  //     transform: translateX(-100%);
  //   }
  //   to {
  //     transform: translateX(0);
  //   }
  // }
>div{
    //flex: 1 0 10%; 
    margin: 0 10px 0 10px;
}

.clipFilterSelect {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 7.5rem;
  max-width: 14.5rem;
  margin: 0.3rem 0 0.3rem 0;
  div{
    max-width: 14.5rem;
    overflow-x: hidden;
  }
}


.clipFilterSelectChannelGroup{
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 14.5rem;
  min-width: 5rem;
  margin: 0.3rem;
  div{
    max-width: 14.5rem;
    overflow-x: hidden;
  }
}

.clipSearchButton{
  width: 9rem;
  //margin-block-start: 1.4rem;
}

.clipApplyButton{
  width: 9rem;
  margin-block-start: 2rem;
}

.clipSearchHistoryButton{
  margin-block-start: 1.4rem;
}

.clipFilterDateRangePicker{
  height: 2.45rem;
  color: #23527c;
  margin-top: 1.1rem;
  background-color: #ffffff;
  >div{
    border-radius: 0.2rem;
  }
}

.clipFilterSelectContainerHidden {
  @keyframes slideOutToLeft {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
}


  .filterItemContainer{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.4rem;
    flex: 0.95;
  
    .filterRemove {
      position: absolute;
      left: 10.8rem;
      top: 2.7rem;
      font-size: 1.1rem;
      &:dir(rtl) {
        left: 2.8rem;
        right: auto;
      }
      cursor: pointer;
      &:hover{
        color: #23527c;
      }
      @media screen and (max-width: 768px) {
        order: -1;
      }
    }
    
    .customRemoveIcon{
      position: absolute;
      top: 2.7rem;
      font-size: 1.1rem;
      &:dir(rtl) {
        left: 2.8rem;
        right: auto;
      }
      cursor: pointer;
      &:hover{
        color: #23527c;
      }
      @media screen and (max-width: 768px) {
        order: -1;
      }
    }
}
}
.groupSelectRemoveIcon{
  position: absolute;
  left: 10.4rem !important;
  top: 2.5rem !important;
  font-size: 1.1rem;
  &:dir(rtl) {
    left: 2.8rem;
    right: auto;
  }
  cursor: pointer;
  &:hover{
    color: #23527c;
  }
  @media screen and (max-width: 768px) {
    order: -1;
  }
}
.searchProfileHistoryContainer{
  display: flex;
  flex-direction: column;
  width: max-content;
  transition: height 0.3s ease; 
  margin-block-start: 0.5rem;
  margin-block-end: 0.5rem;
  border-radius: 0.2rem;
  position: absolute;
  word-break: break-word;
  overflow: hidden;

  
  

  .searchProfileHistoryItemActive{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.3rem;
    width: 12rem;
    padding: 0.6rem;
    height: 1rem;
    border-radius: 0.2rem;
    background-color: #23527c;
    color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    &:hover{
      box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px;
      cursor: pointer;
    }
    .searchProfileHistoryItemText{
      font-size: 1rem;
      color: white;
      letter-spacing: 0.04rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }
}

.deleteWrapper{
  padding: 0.3rem;
  padding-inline-end: 0.5rem;
  padding-inline-start: 0.5rem;
  &:hover{
    background-color: #ebebeb;
    border-radius: 0.1rem;
  }
}

.groupSelectionContainer{
  display: flex;
  flex-direction: column;
  position: relative;
}

.searchProfileHistoryItem{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.3rem;
  width: 12rem;
  padding: 0.6rem;
  border-radius: 0.2rem;
  height: 1rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  background-color: #f5f5f5;
  
  &:hover{
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px;
    cursor: pointer;
  }
  .searchProfileHistoryItemText{
    font-size: 1rem;
    color: #23527c;
    letter-spacing: 0.04rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: #f5f5f5;
  }
}

.searchSectionLabel{
  font-size: 0.8rem;
  font-weight: bold;
  -webkit-margin-start: 0.5rem;
  margin-inline-start: 1rem;
  height: fit-content;
}

.searchHistoryContainer{
  display: flex;
  flex-direction: column;
  top: 1rem;
  position: relative;
  z-index: 9999;
  

}

.channelSelectionContainer{
  display: flex;
  flex-direction: column;
  margin-block-start: -0.5rem;
}

.searchButtonContainer{
display: flex;
justify-content: center;
align-items: center;
margin-block-start: 1.4rem;
margin-block-end: 1rem;
}

.searchProfileHistoryItemRemoveActive{
color: white;
}

.searchProfileHistoryItemRemove{
color: #23527c;
&:hover{
  color: #4686be;
}
}