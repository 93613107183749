.wrapper
{
    position: relative;

    display: flex;
    align-items: center;

    width: 100%;
    height: 2.5rem;

    margin-block-start: 0;

    box-shadow: 0 13px 10px -10px #0003;

    z-index: 3;
    border-block-end: 1px solid black;
}

.icon
{
    font-size: 1.3rem;

    height: 100%;

    /* border-block-end: 1px solid black; */

    padding: 1px .6rem;
    padding-inline-end: 1rem;
}

.autocomplete{ width: 100%; }

.input input
{
    background-color: #FFF0;
    
    border: none;
    /* border-block-end: 1px solid black; */
    border-radius: 0;

    font-size: 1.3rem;
    width: 100%;
    height: 100%;

    margin: 0;

    z-index: 3;
    padding: 0;
    padding-block-end: 0.3rem;
}

.input:focus
{
    outline: none;
}