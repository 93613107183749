.card{
    background-color: #d7dde9;
    margin: 0.6rem;
    padding: 0.4rem;
    justify-content: center;
    // max-height: 15rem;
    border: 1px solid gray;
    border-radius: 0.5rem;

    div{
        margin-top: 0.7rem;
        margin-bottom: 0.7rem;
        display: flex;
        justify-content: center;
    }
    .content{
        display: flex;
        flex-direction: column;
        .price{
            font-weight: bold;
            background-color: #fff;
            border: 1px solid gray;
            border-radius: 0.5rem;
            padding:0.3rem;
            padding-top: 0.4rem;
            padding-bottom: 0.4rem;
            text-align: center;
        }
    }
}