.headingContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 0.5rem 0 0.5rem;
    flex-wrap: wrap-reverse;
    @media (max-width: 1280px){
        width: 100%;
        justify-content: space-evenly;
        gap: 1rem;
        margin-bottom: 1rem;
    }
    
    .headingSection{
        display: flex;
        gap: 9.5rem;
        align-items: center;
        @media (max-width: 1280px){
            gap: 1rem;
            text-align: center;
        }
        >h2{
            font-size: 1rem;
            width: max-content;
            @media (max-width: 1280px){
                font-size: 0.9rem;
            }
        }
        
    }
    .buttonSection{
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;
        padding-inline-end: 0.7rem;
        @media (max-width: 1280px){
            gap: 0.1rem;
        }
        @media (max-width: 650px){
            justify-content: center;
        }
    }
    .mobileButtonPadding{
        padding: 0 0.2rem;
        font-size: 0.9rem;
    }
}