.wrapper{
    padding-inline: 4rem;
    padding-block: 2rem;
    max-width: 60rem;
    h2{
        text-align: start;
    }
    .label{
        font-weight: bold;
    }

    .payment{
        width: 100%;
    }
    .center{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}