@mixin sentimentIconsFlex {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 1.5rem;
    max-width: 100%;
    flex-wrap: wrap;
    @include animation('fadeInAnimation 2s');
    flex: auto;
    width: auto;
    padding: 1rem;
}

@mixin sentimentIcon($color, $size){
    color: $color;
    font-size: $size;
    position: absolute;
    top: 0;
    right: -0.7rem;
}

@mixin moreIcon($shadow) {
    object-fit: contain;
    width: 2.2rem;
    height: 2.2rem;
    //box-shadow: $shadow;
    .sentimentIcon{
        font-size: 1rem;
        z-index: 99;
    }
}

@mixin kfIcon($shadow) {
    object-fit: contain;
    width: 4rem;
    height: 3rem;
    //box-shadow: $shadow;
    object-fit: cover;
    img{
        border-radius: 0.4rem;
    }
}

@mixin progressBar($backgroundColor){
    background-color: $backgroundColor;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.2rem;
    color: #ffffff;
    font-weight: 500;
    text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.24);
    
}

@mixin sentimentOverlayPosition{
    position:static;
}

@mixin keyframes($animation-name){
    @-webkit-keyframes #{$animation-name}{
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($str) {
    -webkit-animation: #{$str};
    animation: #{$str};
}

@include keyframes(fadeInAnimation) {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }

.overviewClusterContainer {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    padding: 1rem 0 1rem 0;
    width: 100%;
    border-radius: 0.2rem;
    gap: 1rem;
    display: flex;
    height: fit-content;
    flex-wrap: wrap;
    justify-content: center;
    @media(max-width: 1280px){
        padding: 0.5rem 0 3rem 0;
    }
    .thumbnail {

        object-fit: contain;
        width: 100%;
        border-radius: 0.2rem;
        max-height: 10rem;
    }

    .cardHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        position: relative;

    }

    .midWrapper {
        display: flex;
        flex-direction: column;
        width: 80%;
        justify-content: center;

        .clusterHeading{
            line-height: 1.3rem;
            >h4{
                font-size: 1.2rem;
                margin: 0;
                @media(max-width: 864px){
                    text-align: center;
                }
            }
            >p{
                @media(max-width: 864px){
                    text-align: justify;
                } 
            }
            
        }

        .iconsContainer {
            display: flex;
            justify-content: space-evenly;
            flex-wrap: wrap;
            @media (max-width: 1280px) {
                flex-direction: column;
                flex-wrap: nowrap;
            }
            .imagesContainer{
                display: flex;
                flex-direction: column;
                align-items: center;
                .kfActive{
                    @include kfIcon(rgba(3, 102, 214, 0.3) 0px 0px 0px 3px);
                    border: 2px solid rgb(233, 177, 74);
                    box-sizing: border-box;
                    border-radius: 0.1rem;
                }
                .kfNotActive{
                    @include kfIcon(rgba(0, 0, 0, 0.24) 0px 3px 8px);
                    transition: transform .4s ease-out;
                    border-radius: 0.1rem;
                    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
                    &:hover{
                        transform: scale(1.2);
                        cursor: pointer;
                    }
                }
                .moreIconNotActive{
                    @include moreIcon(rgba(0, 0, 0, 0.24) 0px 3px 8px);
                }
                .moreIconActive{
                    @include moreIcon(rgba(3, 102, 214, 0.3) 0px 0px 0px 3px)
                }
                
            }
            .sentimentsWrapper{
                flex: auto;
                @media (max-width: 1280px) {
                    display: flex;
                    flex-direction: row-reverse;
                }
                .positiveProgressBar{
                    @include progressBar(#4CAF50);
                    border-top-left-radius: 0.2rem;
                    border-bottom-left-radius: 0.2rem;
                }
                .neutralProgressBar{
                    @include progressBar(#d3d3d3);
                }
                .negativeProgressBar{
                    @include progressBar(#f82828);
                    border-top-right-radius: 0.2rem;
                    border-bottom-right-radius: 0.2rem;
                }
            }
            .positiveSentimentsContainer {
                @include sentimentIconsFlex;
            }

            .neutralSentimentsContainer {
                @include sentimentIconsFlex;
            }

            .negativeSentimentsContainer {
                @include sentimentIconsFlex;
            }
        }
    }

    .iconBox{
        position: relative;
        background-color: #ffffff;
         @include animation('fadeInAnimation 3s');
        .positive {
            @include sentimentIcon(#4CAF50, 1rem);
        }

        .positive-overlay{
            @include sentimentOverlayPosition();
        }
    
        .negative {
            @include sentimentIcon(red, 1rem)
        }

        .negative-overlay{
            @include sentimentOverlayPosition();
        }
    
        .neutral {
            rotate: -90deg;
            @include sentimentIcon(#666666, 1rem)
        }

        .neutral-overlay{
            @include sentimentOverlayPosition();
        }

        &:hover{
            cursor: pointer;
        }
        .modalContainer{
            position: absolute;
            background-color: #ffffff;
            box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
            border-radius: 0.3rem;
            left: 1.5rem;
            top:4rem;
            transform: translate(-20%, -0%);
            z-index: 99;
            @include animation('fadeInAnimation 1s');
            
            @media(max-width: 1280px){
                position: fixed;
                top: 40%;
                left: 50%;
                transform: translate(-50%, -90%);
            }
            
        }
    }

    
}

.sentimentContainerHide{
    display: none;
}

.showMoreButton {
    border: none;
    border-radius: 0.3rem;
    transition: transform .4s;
    background-color: #f0efef;
    padding: 0.2rem;
    text-decoration: none;
    color: inherit;
    &:hover {
        transform: scale(1.2);
        text-decoration: none;
    }
    &:active{
        text-decoration: none;
    }
}