.countryContainer {
    display: flex;
    flex-direction: column;
    flex: 0.04;
    min-width: 4rem;
    height: 100%;
    overflow-y: auto;
    background-color: #f7f7f7;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    border-radius: 0.5rem;

    @media (max-width: 864px) {
        overflow-x: auto;
        gap: 0.5rem;
        padding: 0.5rem;
        order: 1;
    }

    .euFlagContainer {
        position: sticky;
        top: 0;
        background-color: #f7f7f7;
        box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
        padding: 0.5rem 0 0.5rem 0;

        &:hover {
            cursor: pointer;
            background-color: #dbdbdb;
        }
    }

    .countryObject {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        height: fit-content;
        padding: 0.4rem;
        border-radius: 0.5rem;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        font-size: 0.9rem;

        &:hover {
            cursor: pointer;
            background-color: #dbdbdb;
            transition: ease-in-out 0.3s;
        }
    }

    .activeCountryObject {
        display: flex;
        gap: 0.5rem;
        height: fit-content;
        align-items: center;
        padding: 0.4rem;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        font-size: 0.9rem;
        background-color: #dbdbdb;
        border-left: 2px solid rgb(35, 82, 124);

        &:hover {
            cursor: pointer;
            background-color: #dbdbdb;
        }
    }

    .languageContainer {
        display: flex;
        flex-wrap: wrap;
        margin-top: 5px;

        .languageRow {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.2rem;
        }
    }

    .language {
        background-color: #f0f0f0;
        padding: 1px 10px;
        margin: 5px;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;
    }

    .language:hover {
        background-color: #d0d0d0;
    }

    .activeLanguage {
        background-color: rgb(215, 139, 33);
        color: white;
        padding: 5px 10px;
        margin: 5px;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;
    }

}