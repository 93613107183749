.wrap
{
  margin: .5rem 0;
}

.label
{
  font-size: .8rem;
  font-weight: bold;
  margin-inline-start: 0.5rem;
}

.options{
  position: static;
}

