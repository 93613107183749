@import '/src/assets/themes/staticBase.module.scss';

.wrapper{
    border: 1px solid gray;
    border-radius: $borderRadius;
    background: $inputBackground;
    display: flex;

    .input{
        border: none;
    }

    .button{
        border: none;
        padding: 0 1rem;
        border-top-right-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;

        &:hover{
            background-color: lightgray;
        }
    }
}