.home-wrapper
{
    position: relative;
    height: calc(100vh - 20rem);
}

.hometitle
{
    position: relative;
    inset-block-start: 50%;
    text-align: center;
    font-size: 3rem;
    color: #003855;
    z-index: 1;
    
    animation-delay: 1s;
}

.homesubtitle
{
    position: relative;
    inset-block-start: 50%;
    text-align: center;
    font-size: 1.5rem;
    color: grey;
    z-index: 1;

    animation-delay: 1s;
}

.backdrop
{
    position: absolute;
    inset-block-start: 50%;
    inset-inline-start: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
}
html[dir="rtl"] .backdrop{
    transform: translate(50%, -50%);
}

.sun
{
    font-size: 30rem;
    color: #f5822144;
    z-index: 0;
}

@media only screen and (orientation: portrait) and (max-width: 630px)
{
    .hometitle
    {
        font-size: 2rem;
    }

    .homesubtitle
    {
        font-size: 1rem;
    }

    .sun
    {
        font-size: 20rem;
    }
}

@media only screen and (orientation: portrait) and (max-width: 400px)
{
    .hometitle
    {
        font-size: 1.5rem;
    }

    .homesubtitle
    {
        font-size: 1rem;
    }

    .sun
    {
        font-size: 15rem;
    }
}

.actionsWrapper{
    padding-block-start: .7rem;
}