.filteredContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    background-color: #ffffff;
    padding-block: 0.1rem;
}

.allCheckbox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}

.searchInput {
    padding-inline-start: 2.4rem !important;
    border: none !important;
    font-size: 0.9rem;
    @media(max-width: 1341px){
        min-height: 1.5rem !important;
    }
}

.buttonsContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    .button{
        height: 2rem;
        width: calc(60% - 2rem);
        font-size: 0.9rem;
        @media(max-width: 1341px){
            height: 1.5rem;
            width: calc(65% - 2rem);
        }
    }
}

.checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .checkmark {
    display: inline-block;
    vertical-align: middle;
    width: 18px;
    height: 18px;
    border: 1px solid black;
  }
  
  .checkbox:checked + .checkmark {
    background-color: black;
  }
