.container{
    display:flex;
    width:100%;
    height: calc(100% - 2.7rem);
}
.wrapper{
    height:100%;
    overflow: hidden;
}

.checklistsWrapper{
    display:flex;
    width: 100%;
    flex-direction: column;
    .tooManySelectedChannels{
        color: #a94442;
        background-color: #f2dede;
        padding: 1rem;
        .newChannelGroup{
            display: flex;
            flex-direction: row;
            .textInput{
                display: flex;
                align-items: center;
                max-width: 20rem;
            }
        }
    }
}
.checklistWrapper{
    display: flex;
    flex-direction: column;
}