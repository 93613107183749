.widgetSettingsWrapper {
    position: fixed;
    height: calc(100% - 6rem);
    width: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 1;
    overflow: auto;

    .widgetSettings {
        padding: 0.7rem;

        .filtersInfo{
            padding-inline-start: 0.5rem;
            margin-block-end: 1rem;
        }

        .settings > * {
            margin: 0.5rem;
        }
    }
}