/* THIS IS DEPRECATED AND NOT TO BE USED ANYMORE */

/* Basic Layouts */

/* to be discussed: theme css should probably contain colors only ? */

/* sizes the element to 100% available width & height, 
and centers contents */
.centerContents
{
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
}

/*
    Centered, column-directed layout, good for forms
    Sizes the element to 100% available width & height
*/
.formLayout
{
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.bgPrimary{
    background-color: #f58221;
    color: white;
}
.bgSecondary{
    background-color: #23527c;
    color: white;
}

/* INTERFACE COLOURS */
.boardColor
{
    background-color: #F5F5F5;
}

.titlebarColor
{
    background-color: white;
    color: black;
}

.navbarColor
{
    background-color: #23527c !important;
    /* background-image: linear-gradient(123deg, #23527cEE, #23527c); */
    color: #FAFAFA;
}

.borderAccent
{
    border-color: #23527c !important;
}

.borderAccentActive
{
    border-color: #f58221 !important;
}

.accentActive
{
    color: #f58221 !important;
}

.hit, .hitBox [class=hit]
{
    color: #f58221;
}

.textColorPrimary
{
    color: #f58221 !important;
}

.textColorSecondary
{
    color: #23527c !important;
}

.textColorSuccess
{
    color: #4CAF50 !important;
}

.textColorDanger
{
    color: #F44336 !important;
}

/* highlighting for required inputs (eg. profile titles) */
.requirement
{
    color: #23527c !important;
    border-color: #23527c !important;
}

/* for selections of all sorts */
.selectionHover:hover
{
    background-color: #23527c22;

    transition: background-color .1s ease-in-out;
}
.selected
{
    background-color: #23527c44 !important;
    border-color: #f58221;

    transition: background-color .1s ease-in-out;
}

/* OTHER BACKGGROUND COLORS */
.dangerBox
{
    background-color: #F003;
    color: #F44336;
    padding: .5em .2em;
    border-radius: 5px;
    border: 1px solid #F005;
    margin: 1em 0;
}

/* TITLES */
.h1
{
    font-size: 2rem;
    
    margin: .2rem 0;

    color: black;
}

.h2
{
    font-size: 1.5rem;
    
    margin: .2rem 0;

    color: #616161;
}

.h3
{
    font-size: 1.1rem;
    
    margin: .2rem 0;

    color: #757575;
}

.h1Dark
{
    color: #FAFAFA;
}
.h2Dark
{
    color: #BDBDBD;
}
.h3Dark
{
    color: #9E9E9E;
}

/* BUTTONS */

/* Basic, required for the other variations */
.btn
{
    flex: 0 0 auto;

    height: 2.5rem;

    margin: .3rem;
    padding: 0 .8rem;

    background-color: white;
    
    border: none;
    border-radius: 4px;

    font-size: 1.1rem;

    transition: background-color .1s ease-in-out;
}
.btn:hover
{
    background-color: #EEEEEE;
    transition: background-color .1s ease-in-out;
}
.btn:active
{
    background-color: #E0E0E0;
    transition: background-color .1s ease-in-out;
    transform: scale(.99);
}
.btn:disabled
{
    background-color: grey;
}

.btnPrimary
{
    background-color: #f58221;
    color: white;
}
.btnPrimary:hover
{
    background-color: #f58221dd;
}
.btnPrimary:active, .btnPrimary:disabled
{
    background-color: #f58221aa;
}

.btnSecondary
{
    background-color: #23527c;
    color: white;
}
.btnSecondary:hover
{
    background-color: #23527cdd;
}
.btnSecondary:active, .btnSecondary:disabled
{
    background-color: #23527caa;
}

.btnSuccess
{
    background-color: #4CAF50;
    color: white;
}
.btnSuccess:hover
{
    background-color: #4CAF50dd;
}
.btnSuccess:active, .btnSuccess:disabled
{
    background-color: #4CAF50aa;
}

.btnDanger
{
    background-color: #F44336;
    color: white;
}
.btnDanger:hover
{
    background-color: #F44336dd;
}
.btnDanger:active, .btnDanger:disabled
{
    background-color: #F44336aa;
}

/* can be used with the others, content is probably best in icon */
.btnRoundSmall
{
    display: flex;
    justify-content: center;
    align-items: center;

    height: 3rem;
    width: 3rem;

    font-size: 1.1rem;

    border-radius: 100%;
}

.btnRound
{
    display: flex;
    justify-content: center;
    align-items: center;

    height: 4rem;
    width: 4rem;

    font-size: 1.8rem;

    border-radius: 100%;
}

.btnRoundBig
{
    display: flex;
    justify-content: center;
    align-items: center;

    height: 4.7rem;
    width: 4.7rem;

    font-size: 2rem;

    border-radius: 100%;
}

/* Remove Button Outlines */
.btn:focus
{
    outline: none;
}

.dropzone {
    border-style: dashed;
    border-color: #23527c;
    border-radius: 4px;
    cursor: pointer;
    width: calc(100% - 5.5rem);    
    display: flex;
    flex-wrap: wrap;
    padding-block-start: .5rem;
    padding-block-end: .5rem;
}

.dropzone:hover {
    border-color: #23527cdd;
}

.table{
    width:100%;
    border-collapse: collapse;
    border-spacing: 0;
}

.table tr:nth-child(odd) {
    background-color: rgb(226, 226, 226);
}
.table td{
    vertical-align: middle;
    border-block-end: 1px solid black;
}

.slider::-webkit-slider-thumb {
    background-color: #23527c !important;
}
.slider::-moz-range-thumb{
    background-color: #23527c !important;
}
.sliderLabel{
    color: #23527c;
}
.secondaryDisabledBackground{
    background-color: #23527caa;
}