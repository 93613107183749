.backdrop
{
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;

    background-color: #0003;

    width: 100%;
    height: 100%;
}

.popup
{
    position: absolute;
    inset-block-start: 0%;
    inset-inline-start: 50%;
    transform: translate(-50%, 20%);
    
    background-color: #F5F5F5;
    
    max-height: calc(100vh - 25rem);
    width: calc(100vw - 40rem);
    padding-block-start: 1rem;
    padding-block-end: .5rem;
    border-radius: 4px;

    animation-duration: .2s;
}
html[dir="rtl"] .popup{
    transform: translate(50%, 20%);
}

@media only screen and (orientation: portrait)
{
    .popup
    {
        position: fixed;
        inset-block-start: 5%;

        width: 95vw;
    }
}

.selection
{
    max-height: calc(100vh - 25rem);
    height: 100%;
    overflow-y: auto;
}

.title
{
    margin-inline-start: 45%;
    font-weight: bold;
    display: flex;
    text-align: center;
} 


.group
{
    width: 100%;
    margin-block-start: .5rem;
    margin-block-end: 1rem;
}

.groupHeader
{   
    display: flex;
    align-items: center;
    padding: 0.5rem;
}

.ruler
{
    flex: 1 0 auto;
    height: 1px;
    margin: .5rem 1rem;
    overflow: hidden;
    background-color: black;
}

.rulerSmall
{
    flex: 0 0 2rem;
}

.groupButtons
{
    display: flex;
    flex-wrap: wrap;
    margin: 0 .5rem;
}

.experimental {
    background-color: rgb(241, 241, 108);
    text-align: center;
    /* border-block-end: 3px solid black; */
    padding: .3rem;
}
.experimentalWrapper{
    /* border: 3px solid black; */
    margin: 0.2rem 0.5rem;
    border-radius: 5px;
}

.experimentalWrapper button{
    margin:0;
}