.topbarControls{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    padding: 1rem;
    .hideSelectBox{
        width: 100%;
    }
}

.mentionDetailsTypeSelect{
    border: none;
    width: 12rem;
    border-radius: 0.2rem;
    height: 2rem;
}

.clipboardText{
    position: absolute;
    top: 4.2rem;
    border: none;
    border-radius: 0.2rem;
    padding: 0.2rem;
    background-color: #4CAF50;
    color: #FFFFFF;
}