.tag
{
    display: block;

    border-radius: 20px;
    
    background-color: #23527c22;
    color: rgba(0,0,0,0.4);

    margin-inline-end: .3rem;

    padding: .0rem .3rem .0rem .3rem;

    font-size: 0.8rem;

    animation-duration: .2s;
}