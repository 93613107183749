.workspace-wrapper
{
    position: relative;
    /* z-index: 10; */
    height: calc(100vh - 4rem);
    display: flex;
    flex-direction: column;
}

.react-grid-item img {
    pointer-events: auto;
}

.workspace-grid-wrapper
{
    width: 100%;
    overflow-y: auto;
    flex-grow: 1;
}

.workspace
{
    transition: all 0s;
}

@media only screen and (orientation: portrait)
{
    .workspace
    {
        height: calc(100vh - 4rem); /*todo*/
    }
}

.add-widget
{
    position: fixed;
    inset-inline-start: 50%;
    width: 15rem;
    
    z-index: 13;
    height: 2.5rem;
    inset-block-start: 3.5rem;
    transform: translate(0, calc(-100% - .5rem));
    
    font-size: 1.3rem;

    /* background-color: #f5822133;
    color: #003855; */
    
    /* border: 1px solid #003855;
    border-radius: 5px; */

    box-shadow: 0 0 15px 1px #0003;

    animation-duration: .2s;
}
.add-widget:focus
{
    outline: none;
}
.add-widget:active 
{
    transform: scale(.99) translate(0, calc(-100% - .5rem));
}

@media only screen and (orientation: portrait)
{
    .add-widget
    {
        inset-inline-start: calc(50% - 7.5rem);
        /* width: calc(100vw - 21rem);
        inset-inline-start: 20.3rem; */
    }
}

.workspace-element
{
    position: relative;

    background-color: white;
    overflow: hidden;
    
    box-shadow: 0 0 10px 1px #3F51B522;

    border-radius: 5px;
    border-inline-start-width: 5px;
    border-inline-start-style: solid;

    transition: none;
}

.workspace-element-fullscreen
{
    transform: none !important;
}

.widget-fullscreen
{
    position: fixed;
    z-index: 1000;
    inset-block-start: 0;
    inset-inline-start: 0;
    width: 100vw !important;
    height: 100vh !important;

    background-color: white;

    height: 100rem;
    width: 100rem;
}

.widget-wrapper
{
    display: flex;
    flex-direction: column;

    height: 100%;
    
    animation-duration: .3s;
}

.widget-wrapper:focus {
    outline: none;
}

.widget
{
    overflow: auto;
    position: relative;

    width: 100%;
    height: 100%;
}

.width:focus {
    outline: none;
}

.widget-header
{
    display: flex;
    align-items: center;
    
    height: 2.2rem;

    background-color: #FAFAFA;
    border-block-end: 1px solid #23527c;
    color: #212121;

    padding-inline-start: .5rem;
    
    font-weight: bold;

    z-index: 10;
}

.headerButtons{

    width: 100%;
    margin-inline-end: 4rem;
    display: flex;
    justify-content: flex-end;
}

.widget-settings{
    background-color: rgb(250, 250, 250);
    border-block-end: 1px solid gray;
    padding-inline-start: 0.5rem;
}

.widget-content-blocker
{
    position: absolute;
    inset-block-start: 2rem; /* top + height of widget-header */
    inset-inline-start: 0;
    z-index: 998;

    height: calc(100% - 2rem); /* 100% - "top" value */
    width: 100%;
    background-color: rgba(255,255,255,0.8);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    animation-duration: .2s;
    cursor: move;
}
.widget-content-blocker-text-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.widget-content-blocker-text {
    max-width: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* background-color: rgba(255,255,255,0.7); */
}

.remove-widget
{
    position: absolute;
    z-index: 998;
    inset-inline-end: .2rem;
    inset-block-start: .2rem;
    font-size: 1.5rem;
    color: #F44336;
    cursor: pointer;

    animation-duration: .5s;
}

.fullscreen-widget-btn
{
    position: absolute;
    z-index: 998;
    font-size: 1.3rem;
    inset-inline-end: 1rem;
    inset-block-start: .3rem;
    color: #212121;
    
    cursor: pointer;
}

.new-tab-widget-btn
{
    padding-inline-end: 0.7rem;
    color: #212121;
}

.options-widget-btn
{
    padding-inline-end: 0.7rem;
    color: #212121;
}

.minimize-widget-btn
{
    position: fixed;
    z-index: 10000;
    inset-inline-end: 1rem;
}

/* react-grid-layout element */
.react-grid-placeholder
{
    background-color: #003955ad !important;
}

.react-resizable-handle
{
    width: 20px !important;
    height: 20px !important;
    z-index: 999;
}

.react-resizable-handle::after
{
    width: 8px !important;
    height: 8px !important;
    border-right: 3px solid #003855 !important; /* do not replace this line with replaceToLogicalCSSProperties.sh script */
    border-block-end: 3px solid #003855 !important;
}

