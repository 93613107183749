.headingContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .buttonSection{
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;
        @media (max-width: 1280px){
            gap: 0.1rem;
        }
        @media (max-width: 650px){
            justify-content: center;
        }
    }
}
.mobileButtonPadding{
    padding: 0 0.2rem;
    font-size: 0.9rem;
}