.mentionCardContainer {
    display: flex;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-radius: 0.2rem;
    flex-wrap: wrap;
    position: relative;

    @media(max-width: 1280px){
        justify-content: center;
        align-items: center;
        width: calc(100% - 2rem);
        flex: 1;
    }
    &:hover {
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
        transition: ease-in 200ms;
    }

    .sentiment{
        cursor: pointer;
        position: absolute;
        right: 1rem;
        top: 1rem;
    }
    
    .keyframeSection {
        position: relative;
        flex: 0.1;
        height: fit-content;
        @media(max-width: 864px){
            width: fit-content;
            justify-content: center;
            padding-block-start: 1rem;
            height: fit-content;
        }
        .channelIcon {
            
            width: 50px;
            height: 31px;
        }

        .keyframe{
            max-width: 245px;
            max-height: 196px;
            height: 176px;
            width: 245px;
            object-fit: cover;
            @media(max-width: 864px){
                max-width: fit-content;
            }
        }

        img {
            box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
            height: 100%;
            border-radius: 0.2rem;
            &:hover{
                cursor: pointer;
            }
        }

        .playIcon {
            color: #dbdbdb;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            

            &:hover {
                color: #23527c;
                transition: ease-in-out 200ms;

            }
        }
    }

    .titleArea {
        padding-inline-start: 2rem;
        padding-inline-end: 2rem;
        padding-block-start: 1rem;
        padding-block-end: 1rem;
        flex: 0.9;
        display: flex;
        flex-direction: column;
        line-height: calc(1ex / 0.32);
        .title{
            font-weight: 600;
            display: flex;
            gap: 0.5rem;
            width: 100%;
            flex-wrap: wrap;
            @media(max-width: 864px){
                justify-content: center;
            }
        }
        .highlightedChannelName{
            color: #005846;
        }
        @media(max-width: 864px){
            align-items: center;
            flex: 1;
            width: 100%;
            padding: 1.5rem;
            min-width: 15rem;
            
        
        }
    }

}