.tour {
    max-width: 420px;
    min-width: 290px;

    .btns {
        display: flex;
        justify-content: flex-end;
        margin-block-start: 0.5rem;
    }

    .box {
        position: relative;
        border-radius: 15px !important;
        padding-inline: 2rem !important;
        padding-block-start: 1.5rem !important;
        padding-block-end: 1.5rem !important;
        margin-block: 1rem;
        color: white;
        padding: 0.6rem;
        background: white;
        color: black;
        text-align: justify;

        .close {
            position: absolute;
            top: 0.1rem;
            right: 0.4rem;
            padding: 0.5rem;
            cursor: pointer;
        }
    }
    background: none !important;
    box-shadow: none !important;

    h4 {
        margin-top: 0;
    }
}

// .highlighted {
//     margin:0 !important;
//     padding:0 !important;
//     stroke: orange;
//     stroke-width: 4px;
//     stroke-opacity: 1;
// }
// .mask{
//     opacity: 0.7;
// }