.wrap
{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  @media(max-width: 864px){
    justify-content: center;
  }
}

.block
{
  width: 7rem;
  height: 6rem;
  margin-inline-end: .5rem;
  
  border: 3px solid lightgrey;
  border-radius: 4px;

  cursor: pointer;
}
.block > div
{
  height: 2rem;
  width: 100%;
}

.buttons
{
  display: flex;
  height: 2rem;
}
.buttons > div
{
  height: 2rem;
  width: 50%;
}