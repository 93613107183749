.lockButtonWrap
{
  display: flex;
  width: 100%;
}

.lockButtonWrapCollapsed{
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.lockButton
{
  flex: 1 1 auto !important;
}

.lockButtonCollapsed{
  font-size: 0.8rem;
  padding: 0.1rem;
  display: flex;
  gap: 0.2rem;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.text
{
  margin-inline-start: .4rem;
  font-size: 1.2rem;
}

.editIconCollapsed
{
  font-size: 1.2rem;
}

.editIcon
{
  font-size: 1.5rem;
}