.btn
{
  flex: 0 0 auto;

  height: 2.5rem;
  
  margin: .3rem;
  padding: 0 .8rem;

  background-color: white;
  color: white;

  border: none;
  border-radius: 4px;

  font-size: 1.1rem;

  transition: opacity .1s ease-in-out;
}
.btn:hover
{
  opacity: 0.9;
  transition: opacity .1s ease-in-out;
}
.btn:active
{
  opacity: 0.8;
  transform: scale(.99);
  transition: all .1s ease-in-out;
}
.btn:active:disabled{
  transform: none;
}
.btn:disabled
{
  background-color: grey !important;
  opacity: 0.7;
  cursor: default;
}

.addWorkspace {
  margin: 1rem .5rem 0 .5rem !important;
  width: calc(100% - 1rem);
  min-height: 2.5rem;
  height: max-content;
}

.lockBoard
{
  margin: 0;
  margin-block-start: 1.5rem;
  width: 100%;
  min-height: 2.5rem;
  height: max-content;
}

.editBoard
{
  margin: 0;
  margin-block-start: .5rem;
  margin-inline-start: -.5rem;
  width: 100%;
  text-align: left;
  
  background-color: rgba(0, 0, 0, 0);
  color: lightgray;
    
  font-size: 1.5rem;
  font-family: inherit;

  transition: all .2s ease-in-out;
}
.editBoard:hover
{
  color: white;

  transition: all .2s ease-in-out;
}

.widgetSelection
{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  margin: 1rem .5rem 0 .5rem;
  min-width: 9.5rem;
  width: 5rem;
  height: 7rem;
}

.widgetSelection:hover:enabled
{
  opacity: 1;
  transform: scale(0.95);

  /* transition makes it jitter */
  /* transition: all .1s linear; */
}
.widgetSelection:disabled{
  background: repeating-linear-gradient(
  45deg,
  #4c4c4c,
  #4c4c4c 10px,
  #808080 10px,
  #808080 20px
);
  opacity: 0.5;
}

.download{

  height: 1.8rem;
}

.cancel {
  background-color: grey !important;
}

.white{
  color: black;
  border: 1px solid grey;
}