.wrapper 
{
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    height: 100%;

    overflow: auto;

    margin: 0;
    padding: 0;

    background-color: white;
}

.results{
    flex:1;
    overflow: auto;
    height: 100%;
}
.disabled{
    opacity: 0.4;
}
.queryVisWrapper{
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 1;
}
.resultsWrapper{
    position: relative;
    width: 100%;
    height: 100%;
}
.resultsWrapper > .numbers{
    padding: .5rem;
}
.resultsText{
    padding: 1rem;
}