.loginComponent
{
    width: 100vw;
    height: 100vh;
    display: flex;
    position: absolute;
    z-index: 100000;
    .loginBanner {
        max-width: 100%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #003855;
        @media only screen and (max-width: 1400px)
        /* only screen and (orientation: portrait) */
        {
                width: 100vw;
                height: 40vh;
        }
        .bannerImage {
            width: 48%;
            height: auto;
        }
    }
        @media only screen and (max-width: 1400px)
    /* only screen and (orientation: portrait) */
    {
   
        flex-direction: column;
    }

    .loginForm
{
    width: 34vw;
    height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    background-color: #fff;
    
    input{
        font-size: 1.2rem;
        margin: 0.5rem 0 1rem 0;
    
        // width: 25rem;
    
        animation-duration: .3s;
    
        // border: none;
        // border-block-end: 2px solid black;
        &:focus{
            outline: 0;
        }
    }

     >div
    {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        width: calc(100% - 1rem);
        max-width: 25rem;
        margin-block-start: 2rem;
    }

     >button
    {
        min-width: fit-content;

        margin-inline-end: 0;
    }
    @media only screen and (max-width: 1400px)
    /* only screen and (orientation: portrait) */
    {
            width: 100vw;
            height: calc(60vh - 3rem);
    
            justify-content: flex-start;
            padding-block-start: 3rem;
    }
}

}

