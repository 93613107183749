.tabBar
{
    position: relative;
    display: flex;
}

.tabsRow
{
    position: relative;

    /* z-index: 10; */

    display: flex;
    justify-items: stretch;
    
    width: 100%;

    box-shadow: 0 13px 10px -10px #0003;
    flex-wrap: wrap;
}

.button
{
    height: 2rem;

    flex: 1 0 auto;

    border: none;

    background-color: #ECEFF1;
    font-size: 1.3rem;

    transition: all .2s ease-in-out;

    border-inline-end: 1px solid #d2d2d2;
    border-block-end: 1px solid #d2d2d2;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.button:hover
{
    background-color: #d2d2d2;
}

.lightText
{
    color: white;    
}

/* CUSTOM STYLES */
.form
{
    z-index: 0;
    box-shadow: none;

    /* border-radius: 4px; */

    width: 100%;

    margin: 0;

    overflow: hidden;
}
.dropDown
{
    position: absolute;
    display: flex;
    flex-direction: column;
    inset-inline-end: 0;
    z-index: 1;
    overflow: auto;
    height: 350px;
    z-index: 3;
}

.dropDown .button
{
    justify-content: space-between;
}

.dropDownOverlay
{
    position: fixed;
    inset-block-start:0;
    inset-inline-start:0;
    width: 100%;
    height: 100%;
    z-index: 2;
}