.wrapper {
    width: 100%;
    margin: 1rem;
    
    .header {
        display: flex;
        margin-bottom: 1rem;
        justify-content: space-between;
    }
    .badges{
        justify-content: space-around;
        display: flex;
        .badge {
            width: 6rem;
            // padding: 2rem;
        }
    }
}