.epgContent
{
    flex: 1 1 auto;
    overflow-y: auto;
    direction: ltr;
}

.epgContentEmpty
{
    display: flex;
    justify-content: center;
    align-items: center;
}

.epgRow
{
    display: flex;
    flex: 1 1 auto;
    align-items: baseline;

    margin: .2rem;
    border-radius: 5px;

    background-color: #ECEFF1;

    div
{
    margin: .2rem;
}
}

.epgAvailable
{
    background-color: #0F02;
}

.epgPartiallyAvailable
{
    background: repeating-linear-gradient(
        45deg,
        #0F02,
        #0F02 10px,
        #ECEFF1 10px,
        #ECEFF1 20px
    );
}

/* .epgNotAvailable
{
    // background: #F002; 
} */

.epgNoshrink
{
    flex: 0 0 auto;
}

.epgRowTitle
{
    display: flex;
    flex: 1 1 auto;
    justify-content: space-between;
    div
{
    display: flex;
    align-items: center;
}
}

.epgRowClickableTitle
{
    background: none;
    border: none;

    width: 100%;
    text-align: left;

    font-size: 1.2rem;
    font-weight: bold;

    cursor: pointer;
}

.epgGenre
{
    font-size: .9rem;
    margin-inline-end: 1rem;
    padding: .1rem;

    color: #f58221;
    border: 2px #f58221 solid;
    border-radius: 5px;
}

.epgUnfoldIcon
{
    font-size: 1.5rem;
    color: #444;

    transition: color .2s ease-in-out;
    &:hover
    {
        color: #f58221;
    
        transition: color .2s ease-in-out;
    }
}

.epgDescription
{
    animation-duration: .2s;
}

.epgRowTitleCard
{
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
}