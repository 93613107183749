.groupsErrorContainer{
    display: flex;
    justify-content: center;
    margin-block-end: 1rem;
    padding: 0;
    text-align: center;
    width: 100%;
    .groupsErrorText{
        font-size: 1rem;
        margin-inline-start: 1rem;
        padding: 0;
        text-align: left;
        width: 100%;
    }
}