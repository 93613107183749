.wrapper
{
    display: flex;
    align-items: center;
    flex-direction: column;

    width: 100%;
    height: 100%;

    overflow-x: hidden;
}

.panel
{
    margin: 0;
    padding: 0;
    
    height: 100%;
    width: 200%;
    
    display: flex;
    
    position: relative;
    inset-inline-start: 50%;
    
    transition: left .2s ease;
}

.panelRight
{
    inset-inline-start: -50%;
    
    transition: left .2s ease;
}

.sidebyside
{   
    overflow: auto;
    overflow-x: hidden;
    
    width: 100%;
    height: calc(100% - 1rem);    
    
    padding-block-start: 1rem;

    animation-duration: .3s;

    display: flex;
    flex-direction: column;
}

.readAll
{
    width: 5rem;
    align-self: flex-end;

    font-size: 1.4rem;
}