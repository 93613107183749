.noAccess {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  
  font-size: 2rem;

  padding: 0 3rem;

  height: 100%;
}

.icon {
  margin-block-end: 1rem;
  font-size: 4rem;
}