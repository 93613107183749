.nav
{
    height: 100vh;
    width: 15rem;
    flex: 0 0 auto;

    box-shadow: inset -2px 0 5px 2px #0003;
    z-index: 13;
    position: relative;
    display: flex;
    flex-direction: column;

    >:last-child{
        margin-bottom: 0.7rem;
    }
}

.nav-collapsed {
    width: 4.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.navLinkWithIconContainer{
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.add-workspace-collapsed {
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    padding: 0.1rem;
}

@media (max-width: 1080px)
{
    .nav-collapsed{
        height: 100vh !important;
        width: 15rem !important;
        flex: 0 0 auto !important;
    
        box-shadow: inset -2px 0 5px 2px #0003 !important;
    }

    .nav
    {
        position: fixed;
        width: fit-content;
        height: calc(100vh - 4rem);
        margin-block-start: 4rem;
        z-index: 10;
        
        transform: translate(-100%, 0);
        transition: transform .2s;
    }
    html[dir="rtl"] .nav{
        transform: translate(100%, 0);
    }

    .display, html[dir="rtl"] .display
    {
        transform: translate(0, 0);
        transition: transform .2s;
    }
}

.nav-away
{
    display: none;
    background-color: #0005 !important;
    animation-duration: .7s;
}

.collapse-nav-icon{
    color: #ffffff;
    display: block;
    text-align: right;
    position: absolute;
    margin-left: 85%;
    margin-top: -0.5rem;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    &:hover{
        color: #cecece;
    }
}

.collapse-nav-icon-collapsed-rtl{
    color: #ffffff;
    display: block;
    margin-right:  36%;
    &:hover{
        color: #cecece;
    }
}

.collapse-nav-icon-collapsed{
    color: #ffffff;
    display: block;
    margin-left:  36%;
    &:hover{
        color: #cecece;
    }
}

.collapse-nav-icon-rtl{
    color: #ffffff;
    display: block;
    text-align: right;
    position: absolute;
    margin-right: 85%;
    margin-top: -0.5rem;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    &:hover{
        color: #cecece;
    }
}

@media (max-width: 1080px)
{

    .collapse-nav-icon{
        display: none;
    }

    .collapse-nav-icon-collapsed{
        display: block;
    }

    .collapse-nav-icon-rtl{
        display: none;
    }

    .collapse-nav-icon-collapsed-rtl{
        display: block;
    }
    
    .nav-away
    {
        display: block;
        position: fixed;
        width: calc(100vw - 10rem);
        margin-inline-start: 10rem;
        height: calc(100vh - 4rem);
        margin-block-start: 4rem;
        z-index: 9;
    }
}

.app-title
{
  background-color: rgb(255, 255, 255);
  height: 3.2rem;
  padding: 0.37rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: inset -5px 0 5px -5px rgba(0, 0, 0, 0.2);
}

.app-title-collapsed
{
    width: 100%;
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 0.4rem;
    box-shadow: inset -5px 0 5px -5px #0003;
}

.collapsedLogo
{
    height: 2rem;
    padding-block-start: 0.5rem;
    padding-block-end: 0.5rem;
}

.nonCollapsedLogo
{
    height: calc(100% - .5rem);
}

.navbar-link
{
    display: block;
    text-decoration: none;
    color: whitesmoke;
    font-size: 1.2rem;
    background-color: #FFF0;
    border: none;
    margin: 0;
    padding: 0.3rem;
    cursor: pointer;
    width: 100%;
    /* text-align: left; */
}
.navbar-link:hover
{
    color: lightgrey;
}


.navbar-link-collapsed
{
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: whitesmoke;
    font-size: 0.8rem;
    background-color: #FFF0;
    border: none;
    margin: 0;
    cursor: pointer;
    width: 100%;
    text-align: center;
}

.navLinkWithIconContainer{
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.workspace-links
{
    margin: 1rem 0;
    // max-height: calc(100vh - 23rem); /* - .header, .bottom, and a bit */
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    flex: 1;
}

.workspace-links-editable
{
    // max-height: calc(100vh - 23rem); /* - add-workspace button */
}

.navLinkTextCollapsed {
    font-size: 1rem;
}

@media only screen and (orientation: portrait)

{

    .app-title-collapsed
{
    background-color: rgba(255, 255, 255, 1);
    height: 3.2rem;
    padding: 0.37rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    gap: 0.5rem;
    justify-content: space-between;
    box-shadow: inset -5px 0 5px -5px #0003;
}

    .navbar-link-collapsed
{
    display: block;
    text-decoration: none;
    color: whitesmoke;
    font-size: 1.2rem;
    background-color: #FFF0;
    border: none;
    margin: 0;
    padding: 0.3rem;
    cursor: pointer;
    width: 100%;
    /* text-align: left; */
}

    // .workspace-links
    // {
    //     max-height: calc(100vh - 19rem); /* - 2x .header, .bottom, and a bit */
    // }

    // .workspace-links-editable
    // {
    //     max-height: calc(100vh - 23rem); /* - add-workspace button */
    // }
}

.workspace-link
{
    padding: 1rem 0;
    padding-inline-start: .5rem;
    display: flex;
    align-items: center;
    border-inline-start-width: 4px;
    border-inline-start-style: solid;
    border-color: #0003;
    
    transition: all .2s;
    animation-duration: .2s;
    @media(max-width: 1080px){
        padding: 0.6rem 0;
    }
}

.workspace-link-collapsed{
    padding: 0.9rem 0;
    display: flex;
    align-items: center;
    border-inline-start-style: solid;
    border-color: #0003;
    
    transition: all .2s;
    animation-duration: .2s;
    @media(max-width: 1080px){
        padding: 0.6rem 0;
    }
}

.workspace-link-active
{
    /* background-color: rgba(0, 0, 0, 0.5); */
    background-image: linear-gradient(to right, #0008, #0005);
    border-inline-start-width: 4px;
    border-inline-start-style: solid;
    /* border color from theme */

    transition: all .2s;
}

.remove-workspace
{
    cursor: pointer;
    margin-inline-end: .5rem;
    visibility: visible;

    transition: margin-right .2s;
    animation-duration: .5s;
    color: #FAFAFA;
}

.remove-workspace-collapsed{
    cursor: pointer;
    margin-inline-end: 0rem;
    visibility: visible;
    transition: margin-right .2s;
    animation-duration: .5s;
    color: #FAFAFA;
}

.remove-workspace-hidden
{
    margin-inline-end: -.9rem;
    visibility: hidden;

    transition: margin-right .2s;
    animation-duration: .2s;
}

/* .bottom
{
    position: absolute;
    inset-block-start: calc(100% - .5rem);
    inset-inline-start: .5rem;
    width: calc(15rem - 1rem);
    height: 4rem;
    transform: translate(0, -100%);
} */

@media only screen and (orientation: portrait)
{

    .workspace-link-collapsed{
        padding: 1rem 0;
    padding-inline-start: .5rem;
    display: flex;
    align-items: center;
    border-inline-start-width: 4px;
    border-inline-start-style: solid;
    border-color: #0003;
    
    transition: all .2s;
    animation-duration: .2s;
    @media(max-width: 1080px){
        padding: 0.6rem 0;
    }
    }

    .bottom
    {
        width: calc(19rem - 1rem); /* navbar width - 2x margin */
    }
}