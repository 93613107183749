.loading-spinner-wrapper
{
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-spinner-wrapper > .prepend-text{
    margin-inline-end: 0.3rem;
    padding-block-end: 2.5px;
}

.loading-spinner-wrapper.full-size{
    width: 100%;
    height: 100%;
}

.loading-spinner
{
    font-size: 10rem;
}