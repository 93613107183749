.issueMsg {
  text-align: center;
  padding: 0 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .heading{
    font-size: 2rem;
    margin-block-end: 1rem;
  }

  .options{
    .option{
      .heading{
        display: block;
        font-size: 1.3rem;
        font-weight: bold;
        margin-block-end:.3rem;
      }
      margin-block-start: 1rem;
      margin-block-end: 1rem;
    }
  }
}

.icon {
  margin-block-end: 1rem;
  font-size: 4rem;
}

.fullHeight{
  height: 100%;
}