.wrap
{
  width: calc(100% - 4px);
  margin: .5rem 0;
  padding: .3rem 0;

  display: flex;
  align-items: center;

  border: 2px solid #ECEFF1;
  border-radius: 4px;
}
.wrapLink{
  justify-content: space-between;
}

.icon
{
  /* color: #ECEFF1; */
  font-size: 2rem;

  margin: 0 1rem 0 .3rem;
}