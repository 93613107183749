.mainWrapper {
  width: calc(100% - 1rem);
  height: calc(100% - 5rem);
  padding-inline-start: 1rem;
  padding-block-start: 1rem;
  overflow-x: scroll;
  .optionsWrap
{
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin: 2rem 1rem 1rem 0;
  .weekdays{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  @media(max-width: 864px){
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }

.changeButtonLabels{
  margin-inline-end: 1.5rem;
  min-width: 22rem;
  display: flex;
  flex-shrink: 0;
  padding: 0.3rem;
}
}
  .settingWrapper{
    border: 2px;
    border-style: solid;
    border-radius: 5px;
    border-color: darkgrey;
    padding: 1rem;
    .selectBoxGroupWrapper{
      display: flex;
      padding-block-end: 1rem;
      flex-wrap: wrap;
      @media(max-width: 864px){
        justify-content: center;
      }
    
    }
  }
  
  @media(max-width: 864px){
    width: calc(100% - 1rem);
    padding-inline-start: 0.5rem;
  }
  .themeMainWrapper {
    display: flex;
    flex-direction: column;
    .controls{
      margin-inline-start: 0rem;
      display: flex;
      justify-content: flex-start;
      @media(max-width: 864px){
        display: flex;
        justify-content: center;
        padding-block-end: 1rem;
      }
    
    }
  }

  .academyWrapper {
    display: flex;
    align-items: center;
    padding-block-end: 1rem;
    @media(max-width: 864px) {
      justify-content: center;
      align-items: center;
    }
  }

  .switchesWrapper{
    display:flex;
    justify-content: center;
    flex-direction: column;
  }

  > h2 {
    margin-block-end: 1rem;
  }

  .adminSettings{
    width: 100%;
    @media(max-width: 864px) {
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      >h3{
        text-align: center;
      }
    }
  }
  
  .adminBorder{
    margin-block-start: 3rem;
    margin-block-end: 3rem;
    height:2px;
    border-width:0;
    color:rgb(187, 184, 184);
    background-color:rgb(160, 159, 159);
    width: 100%;
  }
}

.label
{
  margin-inline-end: 1.5rem;
  display: flex;
  flex-shrink: 0;
  padding: 0.3rem;
}

#switch{
  width: 8rem;
}

.select
{
  flex: 1 0 auto;
  /* width: ; */
  min-width: 13rem;
  max-width: 15rem;
}
.save
{
  /* margin-inline-start: -.1rem; */
  margin-inline-start: 2rem;
}

.themeSelectorWrap
{
display: flex;
flex-direction: column;
padding-block-end: 1rem;
  @media(max-width: 864px){
    align-items: center;
}
}

.list > div:not(:last-of-type){
  border-block-end: 1px solid gray;
}

.langItem{
  display: flex;
  flex-wrap: nowrap;
  background-color: white;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  padding-block-start: 0.4rem;
  padding-block-end: 0.4rem;
}

.labeledSwitch{
  width: 5rem;
  margin-inline-start:0;
  margin-inline-end:0.8rem;
  @media(max-width: 864px){
    width: unset;
  }
}