.item{
    height: 8rem;
    cursor: pointer;
    transition: opacity .15s ease-in-out;
}
.item:hover {
    opacity: 0.8 !important;
    transition: opacity .15s ease-in-out;
}

.header{
    display: flex;
    justify-content: space-between;
    padding: .6rem;
    height: 1.3rem;
    opacity: 0.8;
    color: white;
}
.body{
    width:98%;
    padding-inline-start: .4rem;
    padding-inline-end: .4rem;    
    height: 5rem;
    display: flex;
    background-color: #ffffff;
    border-block-end: 1px solid lightgray;
}
.thumbnailWrapper{
    display: flex;
    align-items: center;
}
.thumbnail{    
    padding-inline-end: 0.3rem;
    max-height:4rem;
    cursor: pointer;
}
.text{
    height: 3.8rem;
    overflow: hidden;
    line-height: 1rem;
    padding: .2rem;
    padding-block-start: .4rem;
    width: 79%;
}

.disableSelection {
    -moz-user-select: none; /* Firefox */
     -ms-user-select: none; /* Internet Explorer */
  -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
 -webkit-user-select: none; /* Chrome, Safari, and Opera */
 -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
}
.inactive{
    opacity: 0.5;
    transition: opacity .15s ease-in-out;
}