@keyframes slideDown{
    0%{
        transform: translate(0, -8rem);
    }
    100%{
        transform: translate(0);
    }
}
@keyframes slideUp{
    0%{
        transform: translate(0, 8rem);

    }
    100%{
        transform: translate(0);
    }
}

.card
{
    flex: 1 1 auto;
    
    height: 25.9rem;
    margin-inline-end: 1rem;
    margin-block-start: 1rem;
    margin-block-end: 1rem;

    min-width: 20rem;
    max-width: 35rem;
    
    border: 1px solid lightgray; 
    border-radius: 4px;
    overflow: hidden;
    
    box-shadow: 0 0 10px 1px #0003;
    background-color: #eeeeee;
}
.body
{
    height: 32rem;
    margin-block-start: -8rem;
    animation-duration: 0.3s;
}

.down{
    animation-name: slideDown;
}

.up{
    animation-name: slideUp;
}

.bodyContainer{
    overflow: hidden;
}

.header
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .4rem;
    padding-inline-start: .5rem;
    height:1.4rem;
    color: white;
}
.unreadCount{
    margin-inline-start: 0.5rem;
    padding-inline-start: 0.2rem;
    padding-inline-end: 0.2rem;
    padding-block-start: 0.1rem;
    padding-block-end: 0.25rem;
    background-color: #fffc;
    color: black;
    border-radius: 4px;
    height: 1rem;
    min-width: 1rem;

    text-align: center;
    display: inline-block;
}

.autoplay
{
    background-color: white;
    border: none;
    padding: .2rem;

    cursor: pointer;

    border-radius: 4px;

    transition: all .15s ease-in-out;
}
.autoplay:hover
{
    opacity: .9;
    transition: all .15s ease-in-out;
}
.autoplay:active
{
    transform: translate(0, 1px);

    transition: all .15s ease-in-out;
}