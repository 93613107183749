.selectWrapper {
    position: relative;

    // &::before {
    //     font-family: "Font Awesome 5 Free";
    //     font-weight: 900;
    //     content: "\f104";
    // }

    .select {
        // -moz-appearance: none;
        // -webkit-appearance: none;
        // appearance: none;
        // border: none;
    
        background-color: white;
        border: 1px solid gray;
        border-radius: 5px;
        padding: 0.6rem;
    }
}