.mainWrapper {
    width: calc(100% - 1rem);
    height: calc(100% - 5rem);
    padding-inline-start: 4rem;
    padding-block-start: 2rem;
    overflow-x: scroll;

    .optionsWrap {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        margin: 2rem 1rem 1rem 0;
        .weekdays {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
        @media (max-width: 864px) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
        }

        .changeButtonLabels {
            margin-inline-end: 1.5rem;
            min-width: 22rem;
            display: flex;
            flex-shrink: 0;
            padding: 0.3rem;
            font-weight: bold;

            @media (max-width: 864px) {
                margin-inline-end: 0rem;
            }
        }
    }
    .settingWrapper {
        border: 2px;
        border-style: solid;
        border-radius: 5px;
        border-color: darkgrey;
        padding: 1rem;
    }

    @media (max-width: 864px) {
        width: calc(100% - 1rem);
        padding-inline-start: 0.5rem;
        padding-block-start: 1rem;
    }

    .switchesWrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    > h2 {
        text-align: start;
        margin-block-end: 1rem;
        @media (max-width: 864px) {
            text-align: center;
        }
    }

    .controls {
        margin-inline-start: 0.3rem;
    }
}

.label {
    margin-inline-end: 1.5rem;
    display: flex;
    flex-shrink: 0;
    padding: 0.3rem;
    font-weight: bold;

    @media (max-width: 864px) {
        margin-inline-end: 0rem;
    }
}

#switch {
    width: 8rem;
}

.select {
    flex: 1 0 auto;
    min-width: 13rem;
    max-width: 15rem;
}
.save {
    margin-inline-start: 2rem;
}

.list > div:not(:last-of-type) {
    border-block-end: 1px solid gray;
}

.langItem {
    display: flex;
    flex-wrap: nowrap;
    background-color: white;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    padding-block-start: 0.4rem;
    padding-block-end: 0.4rem;
}

.labeledSwitch {
    width: 5rem;
    margin-inline-start: 0;
    margin-inline-end: 0.8rem;
    @media (max-width: 864px) {
        width: unset;
    }
}
