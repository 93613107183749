.groupcards-add-wrapper
{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 4;

    height: 5rem;

    position: absolute;
    inset-block-start: 100%;
    inset-inline-end: 0rem;
    transform: translate(-1rem, -5.5rem);
}
html[dir="rtl"] .groupcards-add-wrapper{
    transform: translate(1rem, -5.5rem);
}

.add-input
{
    background-color: #FFF;
    
    border: 1px solid gray;
    border-inline-end: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

    font-size: 1.2rem;

    height: 2rem;

    padding: .5rem;
    padding-inline-end: 1rem;
    margin-block-start: 0;
    margin-inline-end: -.7rem;
}
html[dir="rtl"] .add-input{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.btnRound
{
    display: flex;
    justify-content: center;
    align-items: center;

    height: 4rem;
    width: 4rem;

    font-size: 1.8rem;

    border-radius: 100%;
}