.table {
    width: 100%;
    border-collapse: collapse;

    td, th {
        padding: 0.3rem;
    }

    .thead {
        // border-block-end: 2px solid black;

        .headerCell {
            color: black;
            font-weight: bold;
            border-inline-end: 2px solid black;
            border-block-end: 2px solid black;
        }

        .headerCell:last-of-type {
            border-inline-end: none;
        }
    }

    .tfoot {
        border-block-start: 2px solid black;

        .footerCell {
            font-weight: bold;
            border-inline-end: 2px solid black;
            text-align: right;
        }

        .footerCell:last-of-type {
            border-inline-end: none;
        }
    }

    .bodyRow {
        position: relative;

        .bodyCell {
            // padding: 10px;
            border-inline-end: 2px solid black;
            text-align: right;
            a{
                text-decoration: none;
                color: #000000;
                cursor: default;
            }
        }

        .bodyCellClickable{
            @extend .bodyCell;
            a{
                cursor: pointer;
            &:hover{
                color:rgba(46, 46, 46, 0.7);
                transition: 300ms ease-in-out;
            }

            }
        }
    
        .bodyCell:last-of-type {
            border-inline-end: none;
        }
    }
    
    .bodyRow:nth-child(2n+1) {
        td {
            background-color:lightgray;
            background-clip: padding-box;
        }
    }

    .progressOverlay {
        position: absolute;
        inset-inline-start: 0;
        inset-block-start: 0;
        background: rgba(255,255,255,0.7);
        color: black;
        height: 100%;
        text-align: center;
        vertical-align: middle;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
    }
    
    .progress {
        position:absolute;
        inset-inline-start: 0;
        inset-block-start: 0;
        background: green;
        opacity: 0.5;
        height: 100%;
        padding: 0;
    }

    .clickable {
        cursor: pointer;
    }
}