.wrapper
{
    width: 100%;
    height: calc(100% - 7rem);

    text-align: center;

    position: relative;
}

.legend
{
    inset-block-start: 0;
    inset-inline-end: 0;
    width: 12rem;
    max-height: 100%;

    overflow: auto;

    position: absolute;
    background-color: #FFFB;

    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

    transition: opacity .2s ease-in-out;
}
.legend:hover
{
    opacity: .3;

    transition: opacity .2s ease-in-out;
}