.companyCardContainerOpened {
    position: relative;
    max-height: max-content;
    margin: .5rem 0.5rem .25rem 0.5rem;
    padding: .5rem;
    background-color: #ECEFF188;
    border: 1px solid lightgray;
    border-radius: 4px;
    transition: .2s border-color ease-in-out;
    &:hover{
        border-color: #8f8f8f;
        cursor: pointer;
    }
}

.companyCardContainerClosed {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ECEFF188;
    border: 1px solid lightgray;
    border-radius: 4px;
    margin: .25rem 0.5rem .25rem 0.5rem;
    padding: .5rem;
    font-weight: bold;
    transition: .2s border-color ease-in-out;
    &:hover{
        border-color: #8f8f8f;
        cursor: pointer;
    }
}

.companyCardContent {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    // align-content: center;
    .isActiveCheckboxContainer{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 0.5rem;
        margin-bottom: 2rem;
            
    }
}

.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0.5rem 0 0.5rem 0;

    .companyCardHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        margin: 0;
        font-size: 1rem;
    }

    .companyCardArrowsContainer{
        display: flex;
        align-items: center;
        gap: 1rem;
    }
}


.companyEditContainer{
    top: 0;
    position: absolute;
    background-color: #ffffff;
    z-index: 999;
    width: 100%;
    height: 100%;
}