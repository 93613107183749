.table{
    width:100%;
    border-collapse: collapse;
    border-spacing: 0;
}
.table tr:nth-child(odd) {
    background-color: rgb(226, 226, 226);
}
.table td{
    vertical-align: middle;
    border-block-end: 1px solid black;
    padding: 0.7rem;
    border-block-start: 1px solid black;
}