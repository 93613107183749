.divider{
    display: flex;
    & > *{
        height: 0px;
        width: 100%;
    }
    :first-child{
        margin-inline-end: 1rem;
    }
    :last-child{
        margin-inline-start: 1rem;
    }
}