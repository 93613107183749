.epg-filter
{
    margin: 0 .5rem;
    height: 19rem;
    
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;

    overflow-y: auto;
}

.epg-divider
{
    margin-block-start: 1rem;
    width: 95%;
}

.epg-sidebar-container
{
    display: flex;
    flex-direction: column;
}

.epg-sidebar-comboboxes > div
{
    margin: .2rem 0;
}

.epg-sidebar-comboboxes > div.select-channel
{
    border-style: solid;
    height: fit-content;
    border-radius: 6px;
}


.datepicker-full-width > div
{
    width: 100%;
}

.epg-selection-label
{
    display: inline-block;
    width: 6.5rem;
}

.epg-selection-container
{
    display: flex;
    align-items: center;
}

#hour-selection, #minute-selection
{
    margin-inline-end: .3rem;
    font-size: 1.2rem;
    width: 3rem;
    height: 1.8rem;
    text-align: center;
}

.epg-minute-button
{
    font-size: 1rem;
    height: 2rem;
    padding-block-start: 0;
    padding-block-end: 0;
}

.epg-sidebar-container > div
{
    /* display: flex; */

    margin: .2rem 0;
}

.search-button
{
    background: none;
    border: none;
    border-block-end: 1px solid black;
    font-size: 1.2rem;
}

.epg-program-search-container
{
    display: flex;
    margin-block-start: 1rem !important;
    
}

.epg-program-search
{
    font-size: 1.2rem;
    
    width: 100%;

    border: none;
    border-block-end: 1px solid black;
}