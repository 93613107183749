.overlayWrapper{
    position: relative;
    .overlay{
        position: absolute;
        inset-block-start:0;
        inset-inline-start:0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.3);
    }
}