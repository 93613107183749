.toggleSwitchLabelContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #d1d1d1;
    border-radius: 30px;
    position: relative;
    cursor: pointer;
    height: 1.5rem;
    //margin: 10px;
    width: 15rem;
  }
  
  .switchCheckbox {
    display: none;
  
    &:checked + .toggleSwitchLabelContainer .toggleSwitch {
      left: 50%;
    }
  
    &:checked + .toggleSwitchLabelContainer .toggleSwitchOption {
      &.option1 {
        color: #999;
      }
  
      &.option2 {
        color: #fff;
      }
    }
  }
  
  .toggleSwitch {
    width: 50%;
    height: 1.5rem;
    background-color: #23527c;
    border-radius: 25px;
    position: absolute;
    transition: left 0.3s ease-in-out;
  }
  
  .toggleSwitchOption {
    width: 50%;
    text-align: center;
    z-index: 0;
    color: #666;
    font-size: 13px;
    transition: color 0.3s ease-in-out;
  
    &.option1 {
      color: #fff;
    }
  
    &.option2 {
      color: #666;
    }
  }