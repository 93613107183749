.groupcard
{
    position: relative;

    height: auto;
    max-height: 30rem;
    width: calc(100% - 2rem);
    
    overflow: auto;

    margin: .25rem;
    padding: .5rem;

    background-color: #ECEFF188;
    border: 1px solid lightgray;
    border-radius: 4px;

    transition: .2s border-color ease-in-out;
}
.groupcard:hover
{
    border-color: gray;

    transition: .2s border-color ease-in-out;
}

.groupcard-content
{
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;

    min-height: 5rem;
}

.groupcard-content-list
{
    /* display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center; */

    min-height: 5rem;
}

.groupcard-content > div {
    display: flex;
    align-items: center;
}

.groupcard-content-list > div {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.add-button-wrapper-usermanager{
    display: flex;
    position: absolute;
    right: 1rem;
    top: 0.2rem;
    z-index: 999;
}


.item-add-button
{
    height: 40px;
    width: 40px;

    font-size: 1rem;

    margin: .5rem;
}

.btnRoundSmall
{
    display: flex;
    justify-content: center;
    align-items: center;

    height: 3rem;
    width: 3rem;

    font-size: 1.1rem;

    border-radius: 100%;
}

.dropzone {
    border-style: dashed;
    border-color: #23527c;
    border-radius: 4px;
    cursor: pointer;
    width: calc(100% - 5.5rem);    
    display: flex;
    flex-wrap: wrap;
    padding-block-start: .5rem;
    padding-block-end: .5rem;
}
.dropzone:hover {
    border-color: #23527cdd;
}
