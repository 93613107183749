.all-done
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-size: 2rem;
    color: #000A;

    height: 100%;
}

.all-done-icon
{
    font-size: 10rem;

    margin-block-end: 1rem;
}