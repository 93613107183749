.mainContainer{
    display: flex;
/*     justify-content: space-between; */
    width: calc(100% - 2rem);
    height: calc(100% - 2rem);
    position: relative;
/*     flex-wrap: wrap; */
    padding-inline: .5rem;
    padding-block: 1rem;
    @media (max-width: 1384px){
        flex-direction: column;
        height: 100%;
    }
}

.addRemovePopupContainer{
    display: flex;
    flex-direction: column;
    position: relative;
    height: fit-content;
    gap: 0.5rem;
    @media (max-width: 1384px){
        width: 100%;
    }
    >h3{
        margin: 0;
    }
    >p{
        margin: 0.5rem;
    }
    .selectBox{
        margin: 0.5rem 0 0.5rem 0;
        
    }
}

.popup{
    background-color: red;
}

.noAccessWarning{
    color: #F44336;
}