.iframe-wrapper
{
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
}

.iframe-go-back-button
{
    width: 5rem;
    margin: .5rem 0;
    
    background: none;
    border: none;

    font-size: 1.2rem;
    font-weight: bold;
}

.iframe-and-loading-wrapper
{
    height: calc(100% - 2px);
    width: 100%;
    
    position: relative;
}

.iframe-loading-indicator
{
    font-size: 5rem;
    position: absolute;
    inset-block-start: calc(50% - 2.5rem);
    inset-inline-start: calc(50% - 2.5rem);
}

.iframe
{
    width: 100%;
    height: 99%;
    border: 0;
}