.mentionResultsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow: scroll;
    position: relative;
    .mentionDetailsCards{
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding-block-start: 2rem;
        padding-block-end: 5rem;
        width: calc(60% - 2rem);
        @media (max-width: 1200px){
            width: 90%;
        }
    }
    .topbarControls{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 70%;
    }
    .mentionDetailsTypeSelect{
        border: none;
        width: 12rem;
        border-radius: 0.2rem;
        height: 2rem;
    }
    .wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}