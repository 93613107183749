.wrapper
{
    display: flex;
    flex-direction: column;
    align-content: center;

    width: 100%;
    text-align: center;
}

.rowWrapper
{
    overflow: auto;
    display: flex;
}

.header
{
    /* height: 1.5rem; */

    border-block-end: 2px solid black !important;
    margin-block-end: .1rem;

    background-color: #FFF !important;
}
.header span
{
    cursor: pointer;
}

.footer
{
    border: none !important;
    border-block-start: 2px solid black !important;

    margin-block-start: .1rem;

    background-color: #FFF !important;
}

.row
{
    display: flex;
    align-items: center;
    position: relative;
    /* justify-content: center; */

    /* flex: 1 0 100%; */
    width: 100%;

    padding: .3rem 0;
}
.row > .loadingWrapper {
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: rgba(255,255,255,0.8);
}
.row > .loadingWrapper > .loading {
    background-color: rgb(0, 235, 39, 0.2);   /* TODO: use $success color here */
    height: 100%;
    position: absolute;
}
.row:first-child
{
    border-block-end: none !important;
    height: 1rem;
}
.row:nth-child(3)
{
    border-block-start: 1px solid black;
}
.row:nth-child(even)
{
    background-color: #EEEEEE;
}
.row:nth-last-child(2)
{
    border-block-end: 1px solid grey;
}

.column
{
    display: flex;
    flex-direction: column;
}

.cell
{
    display: flex;
    justify-content: center;

    flex: 1 0 3.5rem;
    min-width: 3.5rem;
    text-align: center;
    overflow: hidden;
}

.cell:not(.wide):not(:only-of-type){
    max-width: 5rem;
}

.clickable:hover
{
    text-decoration: underline;
    cursor: pointer;
}

.dateSpanning
{
    font-size: 1.1rem;
    font-weight: bold;
    flex: 1 0 auto;
}

.dateSpanning hr
{
    width: calc(50% - 5rem);
}

.dateRow
{
    border: none !important;
    background-color: #FFF !important;
}

.total
{
    font-weight: bold;
}

.wide
{
    flex: 1 0 5rem;
    min-width: 5rem;
    /* display: block; */
    /* text-overflow: ellipsis; */
}

.sortedAsc
{
    border-block-end-width: 5px;
    border-block-end-style: solid;
}

.sortedDesc
{
    border-block-start-width: 5px;
    border-block-start-style: solid;
}