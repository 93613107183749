.userInput {
    display: flex;
    flex-direction: column;
    width: auto !important;
    max-width: 30rem;
    margin-left: 2rem;
    margin-right: 2rem;
    > button{
        width: 100%;
    }
    input{
        margin-block: 0;
    }
    p{
        margin-block: 0.6rem;
        margin-block-start: 0.6rem;
    }
    h5{
        margin-block-start: 0.6rem;
        margin-block-end: 0;
    }
}
.setup {
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.select {
    width: 100%;
    margin-bottom: 1rem;
}