.wrapper{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;

    .content{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}