.card
{
    min-height: 6rem;
    width: 98%;

    display: flex;
    flex: 0 0 auto;
    align-items: stretch;
    
    margin: .25rem;


    overflow: hidden;

    transition: background-color .2s ease-in-out;
}
.card:hover
{
    background-color: #F5F5F5;
    
    transition: background-color .2s ease-in-out;
}

.thumbnailWrapper
{
    display: flex;
    align-items: center;

    flex: 1 0 auto;

    background-color: lightgray;

    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

    overflow: hidden;
    width: 120px;
}

.thumbnail
{
    max-height: 6rem;

    background-color: lightgray;

    margin: 0;
    margin-inline-end: -1px; /* better fith with content */
    padding: 0;

    cursor: pointer;
}

.content
{
    display: flex;
    flex-direction: column;

    margin: 0;
    padding: 2px;
    padding-inline-start: .3rem;

    border: 2px solid lightgray;
    border-inline-start: none;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    font-size: .85rem;
}

.content mark
{
    background-color: transparent;
    color: #f58221;
    font-weight: bold;
}

.title
{
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 1.5rem;
    width: 100%;

    margin-block-end: .3rem;
    margin-block-start: .3rem;
}

.title .titleText
{
    display: flex;
    align-items: center;

    font-size: 1rem;
    font-weight: bold;

    cursor: pointer;
}
.title .titleText:hover
{
    color: #424242;
}

.title img /* channel icon */
{
    height: 1.5rem;

    margin-inline-end: .3rem;
}

.title span
{
    margin-block-end: 0;

    font-size: .9rem;
}

.title .timestamp
{
    align-self: flex-start;

    margin-inline-end: .3rem;
    color: #424242;
}

.active{
    .title{
        .titleText, .timestamp{
            color:white;
            &:hover{
                color: rgba($color: white, $alpha: 0.9);
            }
        }
    }
}

.highlightedText
{
    margin-block-start: .2rem;
}

.seenLater
{
    font-weight: bold;

    cursor: pointer;
}
.seenLater:hover
{
    color: #424242;
}

.alsoSeenAt
{
    font-weight: bold;
}

.otherHits
{
    margin: .5rem 0;

    display: flex;
    flex-wrap: wrap;
    flex: 0 0 auto;
}

.otherHit
{
    border-radius: 5px;

    padding: .1rem .2rem .2rem .2rem;
    margin-inline-start: .2rem;
    margin-block-end: .2rem;

    transition: all .1s ease-in-out;
}
.otherHit:hover
{
    color: white;

    cursor: pointer;

    transition: all .1s ease-in-out;
}

.selected
{
    background-color: #E0E0E0;
    transition: background-color .1s ease-in-out;
}