.googleLogo{
    height: 1rem;
    margin-inline-end: 0.6rem;
}

.loginFormWrapper{
    display: flex;
    flex-direction: column;
    > div{
        width: calc(100% - 2rem);
        margin-block-start: 1rem;
    }
    button {
        margin-inline-start: 0;
    }
}

.loginFailed
{
    font-size: 1.3rem;
    margin-inline-end: 2rem;
    color: #F44336;
    
    animation-duration: .4s;
    margin-block-start: 2rem;
}

.loginFailedInput
{
    color: #F44336;
    border-color: #F44336;
}

.textInputWrapper{
    flex-direction: column;

    .textInput{
        margin-top: 0;
    }
}