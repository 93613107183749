.clipFiltersContainer{
    display: flex;
    flex-direction: column;
    margin-block-end: 0.1rem;
    background-color: #f5f5f5;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    position: sticky;
    width: 100%;
    z-index: 100;
    height: fit-content;
  }
  
    .clipFilterTitles {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;
    }
    
    
  
    .clipFilterLabel {
      font-size: 0.8em;
      text-align: left;
      display: block;
      position: absolute;
  }

    .searchBar{
      display: flex;
      align-items: center;
      gap: 0.5rem;
      background-color: red;
      @media screen and (max-width: 768px) {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    
    }
  
    