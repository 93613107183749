.chartTooltip {
    position: absolute;
    background-color: #ffffff;
    color: #2c2c2c;
    padding: 0.5rem;
    border-radius: 0.5rem;
    font-size: 0.8rem;
    top: 1rem;
    right: 0.5rem;
    z-index: 1;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.chartIcon {
    font-size: 1.4rem;
    display: flex;
    gap: 0.2rem;
    align-items: center;
    color: #23527c;
    position: relative;

    &:hover {
        cursor: pointer;
        color: #4da6ff;
        transition: ease-in-out 0.3s;
    }

}


.tooltipContainer {
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    position: fixed;
    z-index: 9000;
    overflow: auto;
    width: 80%;
    transition: opacity 0.3s;
    display: flex;
    align-items: center;
    transform: translate(0%, 0%);
    flex-wrap: wrap;
    border-radius: 0.5rem;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
}

.tooltipContainerGenerating{
    height: 70%;
    width: 100vw;
    background-color: #ffffffb4;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    position: fixed;
    z-index: 9000;
    overflow: auto;
    transition: opacity 0.3s;
    display: flex;
    align-items: center;
    transform: translate(0%, 0%);
    flex-wrap: wrap;
    border-radius: 0.5rem;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
}

.tooltipItem{
    display: flex;
    justify-content: space-between;
    width: fit-content;
    color: #23527c;
    height: 6rem;
    padding: 0.5rem;
    border-radius: 0.2rem;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    border: 2px solid #dbdbdb;
    &:hover{
        border: 2px solid rgb(215, 139, 33);
        cursor: pointer;
    }
}

.tooltipButtonContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

}

.tooltipBoxes{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    gap: 1rem;
}

.tooltipItemSelected{
    display: flex;
    justify-content: space-between;
    width: fit-content;
    color: #23527c;
    height: 6rem;
    padding: 0.5rem;
    border-radius: 0.2rem;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    border: 2px solid rgb(215, 139, 33);
    transition: ease-in-out 0.3s;
    &:hover{
        cursor: pointer;
    }
}

.tooltipItemText{
    font-size: 0.9rem;
    font-weight: 500;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ececec96;
}

.tooltipItemSelectedText{
    font-size: 0.9rem;
    font-weight: 500;
    height: fit-content;
    background-color: #f5f5f5ed;
    transition: ease-in-out 0.3s;
}