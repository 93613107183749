.editViewContainer {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 40rem;
    @media(max-width: 864px){
        width: 100%;
        padding-inline: .5rem;
    }
    .editViewHeader {
        display: flex;
        justify-content: space-between;
    }
    .groupEditInput {
        width: 100%;
    }
    .headerLeft {
        display: flex;
        align-items: center;
        gap: 0.6rem;
        font-size: 1.5rem;
        font-weight: bolder;
        &:hover {
            cursor: pointer;
        }
    }
    .middleSection{
        .duplicateInput{
            padding-inline-end: 0.5rem;
        }
    }
}
