.select {
    &.number {
        min-width: 7rem;
        flex-grow: 0;
        padding-inline-end: .3rem;
    }
    &.unit {
        min-width: 8rem;
        flex-grow: 1;
        padding-inline-start: .3rem;
    }
}
.lastx {
    display: flex;
    margin-block-end: 0.4rem;

    .lastText {
        display: flex;
        align-items: center;
        padding-inline-end: .3rem;
    }
}