.editBoard {
    margin-inline-start: .7rem;
    width: calc(15rem - 1.4rem); /* navbar width - 2x margin */
}

.editBoardCollapsed {
    width: calc(4rem - 1.5rem); /* navbar width - 2x margin */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.toggleAcademy {
    display: flex;
    margin-inline-start: .6rem;
    padding-block-start: 1rem;
}

.toggleAcademyCollapsed {
    margin-inline-start: .2rem;
    padding-block-start: 1rem;
}

.academyButtonCollapsed {
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    margin-inline-start: 0;
}

@media (max-width: 1080px) {
    .editBoard {
        margin-inline-start: .7rem;
    width: calc(15rem - 1.4rem);
    }

    .editBoardCollapsed {
        margin-inline-start: .7rem;
    width: calc(15rem - 1.4rem);
    }

    .toggleAcademy {
        display: flex;
    margin-inline-start: .6rem;
    padding-block-start: 1rem;
    }

    .toggleAcademyCollapsed {
        display: flex;
    margin-inline-start: .6rem;
    padding-block-start: 1rem;
    }

    .academyButtonCollapsed {
        width: unset;
        height: 2.5rem;
        display: unset;
        justify-content: unset;
        align-items: unset;
        font-size: unset;
        margin-inline-start: unset;
    }
}