.tableOuterContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.tableContainer{
    display:flex;
    flex-direction: column;
    //flex: 1;
    //flex-basis: 50%;
    min-width: 50%;
    border: 1px solid lightgray;
    border-radius: 4px;
    margin: 1rem 0.5rem;
    overflow-y: hidden;
/*     width: fit-content; */
    height: calc(100vh - 5rem);
    position: relative;
    @media (max-width: 864px){
        width: 100%;
        flex: initial;
        height: 100vh;
    }
}