.arrow{
    color: black;
    cursor: pointer;
    padding: .3rem;
}

.arrowDisabled{
    opacity: .3;
    cursor: default;
}

.pageCtrl{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-block-end: .5rem;
}