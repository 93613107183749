.duplicateRow{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 2rem;
    
    .duplicateNameInput{
        input{
            width: 15rem;
            @media(max-width: 864px){
                width: 12rem;
            }
        }
    }
    .duplicateInputContainer{
        display: flex;
        gap: 0.5rem;
        @media(max-width: 864px){
            width: 90%;
        }
    }

    .label
{
    display: block;
    animation-duration: .3s;
    font-size: 1.2rem;
    margin-inline-end: 2rem;
}
}