
.graph{
    width: 100%;
    height: 15rem;
    margin-block-start: 1rem;
    color: black;
    background-color: white;
}

.saveQueryBtn{
    width:100%;
    display: flex;
    justify-content: flex-end;
}

.graphHint{
    background-color:black;
    padding: 0.3rem;
    border-radius: .25rem;
    color:white;
}

.textCenter{

    text-align: center;
}

.label
{
    font-size: .8rem;
    font-weight: bold;
    margin-block-end: .3rem;
    margin-block-start: .6rem;
}

.collapsible{
    height: max-content;
    display: flex;
    width: 100%;
    z-index: 1;
    /* flex-wrap: wrap; */

}

.inputContainer{

    display: flex;
    flex-direction: column;
    width: 70%;
    margin-inline-start: 2rem;
    z-index: 1;
}

.rightFlexContainer{

    width: 230px; /* 10% */
    height: fit-content;
    min-width: fit-content;
    flex-direction: column;
    padding: 0.8rem;
    margin-block-start: 1rem;
    margin-inline-start: 2rem;
    margin-inline-end: 0.8rem;
    border-radius: 5px;
    background-color: lightgray;
    flex-wrap: wrap;
    display: flex;

}


.flexInput{
    width: 50%;

}

.flexSelect{
    
    min-width: 20rem;
    overflow: visible !important;
    width: 100%;
}

.flexSelect1{
    z-index: 10000;
}

.flexSelect2{
    z-index: 9000;
}

.flexSelect3{
    display: grid;
    min-width: 10rem;
    max-width: 60rem;
    z-index: 5000;
    width: 100%;
    
} 

.flexChannelGroup{

    display: flex;
    width: 100%;
}

.channelGroupBtn{

    margin-block-start: 1.25rem;
    display: flex;
    border-style: none;
    justify-content: flex-end;
    min-width: fit-content;
    margin-inline-start: 1rem;
    max-width: 10%;
    
}

.title{
    margin-block-start: 9px;
    margin-inline-end: 2px;
    width: 100%
}

.flexToggle{
    display: flex;
    width: 100%;

}

.rightFlexItem{
    display: flex;
    justify-content: space-between;
    margin: .2rem;
    padding: .5rem;
    width: 86%;
    

}

.notifyMail{

display: flex;
align-items: center;
justify-content: space-between;

}

.emailFlex{
    margin: .5rem;
}


.rightDropdown{

     padding: 0.4rem;
     min-width: 65%;
     color: black;
}

.deleteBtn{

    display: flex;
    justify-content: space-between;
    align-self: center;
    align-items: center;
    padding: 0.5rem; 
    width: 81%;

}

.backAndSaveBtns{
    /* height: 4rem; */
    inset-block-start: 0;
    z-index: 2;
    position: sticky;
    width: 100%;
    justify-content: space-between;
    background-color: white;
    
}

.infoBtn{
    margin-inline-start: 1rem;
    margin-inline-end: 1rem;

}

.profileToggle{

    width: 81%;
    align-self: center;
    

}

.notificationContainer{

    width: calc(100% - 1.6rem);
    padding: 0.3rem;
    align-self: center;
    border-style: solid;
    border-color: rgb(231, 225, 225);
    border-width: 3px;
    border-radius: 5px;


}


.clickable{

    cursor: pointer;

}


