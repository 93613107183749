.wrapper{
    max-width: 100%;
    .explanation {
        margin: 1rem 0;
        display: flex;
    }
    .details {
        background-color: rgba(0,0,0,0.08);
        margin: 1.3rem 0;
        padding: 0.6rem;
        border-radius: 10px;
        max-width: 100%;
        word-wrap: break-word;
    }

    .ourFault{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-block-end: 1rem;
        width: 50%;

        img{
            height: 12rem;
        }
    }
    
    .explanationText{
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 45rem;
    }
}