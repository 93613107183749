/* simple 2-button group, that spreads buttons out */
.buttonRow {
    display: flex;
    justify-content: space-between;
    margin-block-start: 1rem;
    margin-block-end: 2rem;
}

.buttonRow button
{
    margin: 0;
}

.right
{
    display: flex;
    justify-content: flex-end;
    align-items: center;

    width: 70%;
}

.label
{
    display: block;

    animation-duration: .3s;

    font-size: 1.2rem;
    margin-inline-end: 2rem;
}