.setting {

    padding-inline-start: .2rem;
    padding-inline-end: .2rem;
    padding: 0.2rem;
    margin-inline-end: 0.2rem;
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;

    // &:not(:last-of-type){
    //     border-inline-end: 3px solid gray;
    // }

    .title{
        padding-inline-end: .3rem;
    }
}