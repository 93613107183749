.itemContainer
{
    background-color: whitesmoke;
    border-radius: 5px;
    height: 100%;
}

.noFound
{
    display: flex;
    justify-content: center;
    align-items: center;
}

.noFoundText
{
    color: gray;
    font-size: 2rem;
    margin: .5rem;
}
.level {
    padding-inline-start: .5rem;
    margin-block-start: 0.3rem;
    margin-block-end: 0.3rem;
}
.levelDown {
    margin-inline-end: 0.5rem;
    transform: rotate(90deg) scaleY(-1);
}