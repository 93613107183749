.wrapper{
    z-index: 100001;
    position: fixed;
    bottom: 0px;
    background-color: orange;
    padding: 0.8rem;

    &.left{
        left: 0px;
    }

    &.right{
        right:0;
    }

    button{
        margin-left: 0.3rem;
        margin-right: 0.3rem;
    }

    .coverageSettings{
        margin-bottom: 1rem;
        // border-bottom: 1px solid black;
    }

    .action{
        background-color: rgba(0, 0, 0, 0.8);
        color: white;
        padding:0.3rem;
        border-radius: 5px;
        border: 1px solid gray;

        &:hover{
            background-color: rgba(0, 0, 0, 1);
            color: white;
        }
    }
    
    .openBtn{
        cursor: pointer;
    }

    .movePos{
        cursor: pointer;
        padding:0.3rem;
        margin-left: 0.5rem;
    }
}