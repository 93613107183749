.wrapper
{
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 100%;
}

.scrollWrap{
    height: 100%;
    overflow: auto;
}

.view
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: .5rem .5rem 0 .5rem;
    width: 90rem;
    height: 100%;
}


