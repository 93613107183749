.groupsOuterContainer{
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    @media (max-width: 1384px){
        width: 100%;
        height: fit-content;
    }
    
    .groupsInnerContainer {
        display: flex;
        flex-direction: column;
        width: 16rem;
        min-width: 10rem;
        margin-block: 1rem;
        margin-inline: 0.5rem;
        padding-inline: 1rem;
        padding-block: 1rem;
        border: 1px solid lightgray;
        border-radius: 4px;
        position: relative;
        overflow-y: auto;
        overflow-x: hidden;
        align-items: center; 
        height: 100vh;
        @media (max-width: 1384px){
            width: calc(100% - 2rem);
        }
    }

    .toggleGroupsOnMobileButton{
        border: none;
        margin-block-end: 1rem;
        margin-inline-start: .5rem;
        border-radius: 0.3rem;
        font-size: 0.9rem;
        >span{
            display: flex;
            align-items: center;
            gap: 0.2rem;
            justify-content: center;
            padding:0.2rem;
        }
    }

    .groupsMobileInnerContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid lightgray;
        border-radius: 4px;
        margin-block: 1rem;
        margin-inline: 0.5rem;
        padding-inline: 1rem;
        padding-block: 1rem;
    }

    .hiddenGroupsMobile{
        display: none;
    }

    .groupsHeading{
        font-size: 0.9rem;
        margin-block-start: 1rem;
        padding: 0;
        font-weight: 500;
        padding-inline-start: 0.5rem;
    }
    >h2{
        font-size: 1rem;
        text-align: start;
        @media (max-width: 1384px){
            font-size: 0.9rem;
            padding-inline-start: .5rem;
        }
    }
}

.groupsHeadingSection{
    margin-block-start: 1rem;
    margin-inline-start: 0.5rem;
    @media (max-width: 1384px){
        margin-block-start: 0rem;
    }
    >h2{
        font-size: 1rem;
        text-align: start;
        @media (max-width: 1384px){
            font-size: 0.9rem;
        }
    }
}

.addButtonContainer{
    position: relative;
}
