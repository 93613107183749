.container {
    width: calc(100% - 4px);
    border: 2px solid #ECEFF1;
    border-radius: 4px;
    z-index: 1;
    margin: .5rem 0;
    transition: border .3s ease-in-out;
}

.wrapper {

    margin-block-start: .3rem;
    z-index: 1;
}

.queryWrap{

    padding: 0.5rem;

}

.category{
    border-radius: 10px;
    padding: 1rem;
    margin-block-start: 0.5rem;
    margin-block-end: 0.5rem;
    position: relative;
}
.categoryDroppableOverlay{
    border-radius: 10px;
    width: 100% !important;
    height: 100% !important;
    position: absolute !important;
    inset-block-start: 0 !important;
    inset-inline-start: 0 !important;
}

.category.alert{
    background-color: #E1EfE5;
}
.category.alert .queryGroup{
    background-color: #B8DDBC;
}

.category.topic{
    background-color: #CFDBE8;
}
.category.topic .queryGroup{
    background-color: #809EC5;
}

.category.discard{
    background-color: #E8CAB6;
}
.category.discard .queryGroup{
    background-color: #CF6522;
}

.queryTextInput {

    margin-block-start: 0;

}

.queriesHeader{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: end;
}

.languageText {
    text-align: left;
    padding-inline-start: 0.8rem;

    height: 3rem;
    border: 2px solid;
    border-radius: 5px;

    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.standardQueryLangText {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.switch{
    margin-inline-end: 0;
}

.rightSideLang{
    
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}


.superQueryLabel{
    display: flex;
    color: white;
    align-items: center;
    border-radius: 3px;
    margin-block-end: 3px;
    padding-block-start: 0.5rem;
    padding-block-end: 0.5rem;
    height: 2rem;
}

.queryGroup{
    border-radius: 5px ;
    padding-block-end: 0.5rem;
    padding-block-start: 0.5rem;
    margin-block-end: 0.5rem;
    margin-block-start: 0.5rem;
    background-color: lightgrey;
    padding-inline-start: 0.5rem;
    padding-inline-end: 0.5rem;
    z-index: 1;
}

.newQueryTitle{

    display: flex;
    z-index: 1;

}

.newQueryBtn{
    display: flex;
    align-items: center;
}
.newQueryBtn button{
    margin:0;
    margin-inline-start: 0.3rem;
}

.queryType{

    align-items: center;
    margin-block-end: 1.2rem;
    width:12%;
    min-width: 14%;
    color: black;
}


.titleInput{
    border-radius: 5px;
    width: 40%;
    font-size: 16px
}
.titleInput:disabled{
    opacity: 100%;
    background-color: rgb(242, 242, 242);
}

.icons{
    display: flex;
    margin-inline-start: 5rem;
    width: 40%;
    align-items: center;
    justify-content: flex-end;
}

.superTranslateBtn{
    margin: 0.1rem;
    padding: 0.6rem;
    border-style: none;
    border-radius: 5px;
    font-size: 16px;
    color: #ECEFF1;

}

.eyeIcon{
    color: white;
}

.infoBtn{
    margin-inline-start: 1rem;
    margin-inline-end: 1rem;

}

/* .noLangSupport{
    opacity: 0.5;
} */

.disabled{
    opacity: 0.4;
}

.selectAsync{
    width: 10rem;
}