.checkboxWrapper{
    padding-inline-start: .7rem;
    padding-inline-end: .7rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
            align-items: center;

    .checkbox{
        position: relative;
        display: flex;
        align-items: center;

        .square{
            background-color: white;
            height: 0.875em;
            width: 0.875em;
            aspect-ratio: 1 / 1;
            display: flex;
            justify-content: center;
            align-items: center;

            .check{
                color: black;
            }

            &.border{
                border: 2px solid gray;
              /*   padding: 0.175rem; */
            }
        }

        .text{
            padding-inline-start: .4rem;
            word-wrap: anywhere;
        }
    }
}