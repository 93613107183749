.table {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  

  .tbody{
    @media (max-width: 1024px) {
      display: flex;
      min-width: 0 !important;
  }
    .noFilterResultText{
      padding-inline-start: 2.5rem;
      font-size: 1rem;
    }
  }

  .headerCell {
    color: black;
    font-weight: bold;
    position: sticky;
    top: 0rem;
    z-index: 99;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0 0.5rem 0;
    @media (max-width: 1024px) {
      display: flex;
      flex-wrap: wrap;
      gap: 0.2rem;
      padding: 0.5rem 2.55rem 0.5rem 2.55rem;
      align-items: center;
      justify-content: space-between;
  }

    .triangle {
      margin-left: 0.2rem;
      font-size: 0.8rem;
    }

    .cell {
      min-width: 5rem;
      flex: 1;
      @media (max-width: 1024px) {
        flex: none;
        min-width: 4.5rem;
        width: 6rem;
        text-align: center;
    }
    }

    .firstColumn {
      min-width: 15rem;
      display: flex;
      align-items: center;
      flex: 1.5;
      @media (max-width: 1024px) {
        min-width: 4rem;
        flex: none;
        justify-content: center;
    }
    }
  }

  ::-webkit-scrollbar {
    width: 3px;
  }
  
  ::-webkit-scrollbar-track {
    background: #F2F2F2;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #BDBDBD;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #6E6E6E;
  }

  // RTL styles
  &.rtl {
    text-align: right;
  }
}

.row{
  display: flex;
}

